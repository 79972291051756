import React, { useMemo } from "react";
import PropTypes from "prop-types";
import UpdateDepartmentButton from "./UpdateDepartmentButton";
import { CUSTOM_ACTION } from "./const";
import UpdateFiles from "./UpdateFilesButton";
import More from "../../../common/components/common/More";
import RemoveButton from "./RemoveButton";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";

function MoreActions({ onAction, row, isOnShift }) {
    const items = useMemo(() => {
        const temp = [
            {
                render: (
                    <UpdateDepartmentButton
                        id={row.id}
                        onClick={() => onAction(row, CUSTOM_ACTION.VIEW_DEPARTMENT)}
                        isIcon
                        withLabel
                        noInternalModal
                    />
                )
            },
            {
                render: (
                    <UpdateFiles
                        id={row.id}
                        style={{ display: "flex" }}
                        onClick={() => onAction(row, CUSTOM_ACTION.VIEW_FILES)}
                        isIcon
                        withLabel
                        noInternalModal
                    />
                )
            }
        ];

        if (!isOnShift) {
            temp.push({
                render: (
                    <RemoveButton id={row.id} onRemove={(eventType) => onAction(row, TABLE_ACTION_TYPE_COMMON.REMOVE, eventType)} isIcon withLabel />
                )
            });
        }
        return temp;
    }, [isOnShift, row.id]);

    return <More items={items} styles={{ items: { paddingRight: ".3rem" } }} isVertical />;
}

export default MoreActions;

MoreActions.propTypes = {
    onAction: PropTypes.func,
    isOnShift: PropTypes.bool,
    row: PropTypes.shape({
        id: PropTypes.number,
        device: PropTypes.shape({
            device_name: PropTypes.string,
            model: PropTypes.string
        })
    })
};
