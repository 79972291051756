import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import NextIcon from "@mui/icons-material/ArrowForwardIos";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import { selectUser, updateUser } from "../../features/common/slice";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import useNavigate from "../../common/hooks/useNavigate";
import Navigation from "../../common/classes/Navigation";

// settings
import Steps from "../../common/components/extra/customSteps/Steps";
import Button from "../../common/components/extra/Button";
import { createConfirmAlert, createToast, TOAST_TYPE } from "../../common/utilities/helper";
import { SETTING_SETUP } from "../../common/utilities/const";
import ProfileCompanyTab from "../../features/company/settings/ProfileCompanyTab";
import GeneralTab from "../../features/company/settings/GeneralTab";
import SalarySettings from "../../features/company/settings/SalarySettings";
import { useUpdateCompanySettingsMutation } from "../../features/company/settings/api";
import PenaltySettings from "../../features/company/settings/PenaltySettings";
import SectionCollapseInfo from "../../common/components/extra/section/SectionCollapseInfo";
import PositionSettings from "../../features/company/settings/PositionSettings";
import { SUPPORTED_SETTINGS_IDS } from "../../features/company/settings/const";

const { PROFILE, GENERAL, SALARY, PENALTY, POSITION } = SETTING_SETUP;

// custom step and not part of the checking only use to detect if user completed all the steps.
const COMPLETED_STEP = {
    id: "COMPLETED",
    name: "Done",
    label: "Completed",
    description:
        "All done! Time to get started by clicking the button below. You can go back to settings by clicking the settings tab in the sidebar."
};

const DIR = {
    NEXT: 1,
    BACK: -1
};

function SettingsSetup() {
    const DASHBOARD_PATH = Navigation.Routes.DASHBOARD.path;

    const [updateSettings] = useUpdateCompanySettingsMutation();

    // use to track steps
    const [steps, setSteps] = useState([]);
    const [current, setCurrent] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [stateDir, setStateDir] = useState(0); // 1 for next or -1 for back

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    const findCurIdx = steps.findIndex((sid) => sid.id === current?.id);
    const currentStepNumber = findCurIdx >= 0 ? findCurIdx + 1 : 1;
    const totalSteps = steps.length;
    const hasBack = findCurIdx > 0;
    const hasNext = findCurIdx < totalSteps - 1;
    const isGoingBack = stateDir === DIR.BACK && processing;
    const isGoingNext = stateDir === DIR.NEXT && processing;
    const isOnPositionStep = current?.id == POSITION.id;
    const hasInvalidDepartment = isOnPositionStep && (user.hasInvalidDepartment || user.hasInvalidAdminDepartment);
    const totalEmployees = user.totalEmployees;

    const createSteps = () => {
        const settingSetupOrderIds = (user.settingSetupOrderIds || []).filter((key) => {
            const validSettings = totalEmployees
                ? Object.values(SUPPORTED_SETTINGS_IDS)
                : Object.values(SUPPORTED_SETTINGS_IDS).filter((id) => id !== SUPPORTED_SETTINGS_IDS.DEPT_DESIG);

            return validSettings.includes(key);
        });

        const completedIds = user.Setting.completed || [];
        return settingSetupOrderIds
            .map((sid, idx) => {
                const item = Object.values(SETTING_SETUP).find((setup) => setup.id === sid) || {};
                const isCompleted = completedIds?.includes(sid);
                return {
                    ...item,
                    stepNumber: idx + 1,
                    name: item.name,
                    error: false,
                    loading: isCompleted ? 100 : false
                };
            })
            .concat({
                ...COMPLETED_STEP,
                stepNumber: settingSetupOrderIds.length + 1,
                error: false,
                loading: false
            });
    };

    const updateStateSteps = (idx, newObj = {}, { dontUpdateState = false } = {}) => {
        const cloneSteps = cloneDeep(steps);
        cloneSteps[idx] = { ...cloneSteps[idx], ...newObj };
        if (!dontUpdateState) setSteps(cloneSteps);
        return cloneSteps;
    };

    const multiUpdateStateSteps = (updateArr = [], { dontUpdateState = false } = {}) => {
        const cloneSteps = cloneDeep(steps);
        const newsteps = cloneSteps.map((cs) => {
            const find = updateArr.find((item) => item.id == cs.id);
            if (find) {
                return { ...cs, ...find };
            }
            return cs;
        });
        if (!dontUpdateState) setSteps(newsteps);
        return newsteps;
    };

    const updateUserSettingSetupConfig = () => {
        dispatch(updateUser({ isSettingsVerified: true }));
    };

    const handleNext = ({ dontUpdateState = false } = {}) => {
        if (!current) return;
        let newCurrent = current;
        const curIndex = steps.findIndex((sid) => sid.id === current.id);
        const maxIndex = steps.length - 1;
        if (curIndex >= 0) {
            const nextIdx = curIndex + 1;
            if (nextIdx <= maxIndex) {
                newCurrent = steps[nextIdx];
            } else {
                newCurrent = steps[maxIndex];
            }
            if (!dontUpdateState) {
                setCurrent(newCurrent);
            }
            return { prevIdx: curIndex, newIdx: nextIdx, current: newCurrent };
        }
        return { prevIdx: curIndex, newIdx: curIndex, current };
    };

    const handlePrev = ({ dontUpdateState = false } = {}) => {
        if (!current) return;
        let newCurrent = current;
        const curIndex = steps.findIndex((sid) => sid.id === current.id);
        if (curIndex >= 0) {
            const prevIdx = curIndex - 1;
            if (prevIdx >= 0) {
                newCurrent = steps[prevIdx];
            } else {
                newCurrent = steps[0];
            }
            if (!dontUpdateState) {
                setCurrent(newCurrent);
            }
            return { prevIdx: curIndex, newIdx: prevIdx, current: newCurrent };
        }
        return { prevIdx: curIndex, newIdx: curIndex, current };
    };

    const handleGetStarted = () => {
        updateUserSettingSetupConfig();
        navigate(Navigation.Routes.DASHBOARD.path);
    };

    const updateCompleted = async (newCompletedIds) => {
        try {
            setProcessing(true);
            const result = await updateSettings({ body: { completed: newCompletedIds } });
            if (result.error) {
                throw new Error(result?.error?.data?.message || "Something went wrong!. Please try again later.");
            }
        } catch (error) {
            createToast("Unable to proceed to next step. Please try again later.", TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setProcessing(false);
        }
    };

    const processStep = async (dir) => {
        let newsteps = [];
        let newCurrent = {};
        const config = { dontUpdateState: true };
        if (dir === DIR.NEXT) {
            const { prevIdx, current } = handleNext(config);
            newCurrent = current;
            newsteps = updateStateSteps(
                prevIdx,
                {
                    loading: 100
                },
                config
            );
        }
        if (dir === DIR.BACK) {
            const { prevIdx, newIdx, current } = handlePrev(config);
            newCurrent = current;
            newsteps = multiUpdateStateSteps(
                [
                    { ...steps[newIdx], loading: 0 },
                    { ...steps[prevIdx], loading: 0 }
                ],
                config
            );
        }
        const newCompletedIds = newsteps.filter((step) => step.loading == 100).map((step) => step.id);
        const result = await updateCompleted(newCompletedIds);
        if (!result?.error) {
            setStateDir(dir);
            setSteps(newsteps);
            setCurrent(newCurrent);
        }
    };

    useEffect(() => {
        const isVerified = user.hasSettings && user.isSettingsVerified;
        if (isVerified) {
            navigate(DASHBOARD_PATH);
        } else {
            const newsteps = createSteps();
            setSteps(newsteps);
            setCurrent(newsteps.find((sw) => !sw.loading));
        }
    }, []);

    const renderSwitch = (type) => {
        switch (type) {
            case PROFILE.id: {
                return <ProfileCompanyTab onLoading={setUpdating} />;
            }
            case GENERAL.id: {
                return <GeneralTab onLoading={setUpdating} />;
            }
            case SALARY.id: {
                return <SalarySettings onLoading={setUpdating} />;
            }
            case POSITION.id: {
                return (
                    <PositionSettings
                        onLoading={setUpdating}
                        styles={{
                            content: {
                                width: "90%",
                                paddingLeft: "2rem",
                                paddingRight: "2rem",
                                margin: "0 auto"
                            }
                        }}
                    />
                );
            }
            case PENALTY.id: {
                return <PenaltySettings />;
            }
            case COMPLETED_STEP.id: {
                return (
                    <div>
                        <Button className="primary" onClick={handleGetStarted}>
                            Get Started
                        </Button>
                    </div>
                );
            }
            default:
                return <></>;
        }
    };

    const createHeaderInfo = (type) => {
        switch (type) {
            case PROFILE.id: {
                return;
            }
            case GENERAL.id: {
                return;
            }
            case SALARY.id: {
                return (
                    <SectionCollapseInfo title="Important Notice" show alwaysOpen>
                        This is your one chance to adjust the salary settings. Please double-check that the values you enter are accurate and final.
                        This will be used for the computation of salary.
                    </SectionCollapseInfo>
                );
            }
            case PENALTY.id: {
                return;
            }
            case COMPLETED_STEP.id: {
                return;
            }
            default:
                return <></>;
        }
    };

    const createControls = () => {
        return (
            <div className="tk-settings-setup__controls flex gap-05">
                {hasBack && (
                    <Button
                        onClick={() =>
                            createConfirmAlert({
                                title: "Back to Previous Step?",
                                content: "You can still go back via next button",
                                onConfirm: (onClose) => {
                                    onClose();
                                    processStep(DIR.BACK);
                                }
                            })
                        }
                        beforeExtra={<BackIcon style={{ width: "1.3rem", marginRight: "-10px" }} />}
                        disabled={isGoingBack || updating}
                        isLoading={isGoingBack}
                        transparent
                        small
                        mini
                    >
                        <span>Back</span>
                    </Button>
                )}
                {hasNext && (
                    <Button
                        className="primary"
                        onClick={() =>
                            createConfirmAlert({
                                title: "Proceed to next step?",
                                content: "You can still navigate back via back button.",
                                onConfirm: (onClose) => {
                                    onClose();
                                    processStep(DIR.NEXT);
                                }
                            })
                        }
                        disabled={isGoingNext || updating || hasInvalidDepartment}
                        isLoading={isGoingNext}
                        afterExtra={<NextIcon style={{ width: "1.3rem", marginLeft: "-10px" }} />}
                        small
                        mini
                    >
                        <span>{processing ? "Processing..." : "Next/Skip"}</span>
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div className="tk-settings-setup">
            <div className="tk-settings-setup__top-ctrls flex column gap-05">
                <h2 className="tk-settings-setup__title">Finish setting up your profile to get started!</h2>
                <div className="steps-container">
                    <Steps className="tk-settings-setup__steps" items={steps} currentIndex={findCurIdx} total={totalSteps} noFirstLine />
                </div>
            </div>
            <div className="wrapper flex column">
                <div className="tk-settings-setup__header flex column center gap-05">
                    {current && (
                        <h3 className="primary-color">
                            Step {currentStepNumber}: {current?.label}
                        </h3>
                    )}
                    {current && (
                        <p style={{ margin: 0 }} className="fade">
                            {current.description}
                        </p>
                    )}
                    {<div className="tk-settings-setup__header-info">{createHeaderInfo(current?.id)}</div>}
                </div>
                <div className="tk-settings-setup__content">{renderSwitch(current?.id)}</div>
                {createControls()}
            </div>
        </div>
    );
}

export default SettingsSetup;
