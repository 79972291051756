import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import Modal from "../../../common/components/extra/Modal";
import AdvanceList from "../../../common/components/extra/AdvanceList";
import Tag from "../../../common/components/extra/Tag";
import { createFullName, createObjectFromKeys, flattenObject, isValid, sanitizeWords, trimToLowerJoin } from "../../../common/utilities/helper";
import { FALSE_VALUES } from "../../../common/utilities/const";
import { STATUS } from "../employees/const";
import { customSortKeys } from "../employees/helper";
import Tooltip from "../../../common/components/extra/Tooltip";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import { getStatusFromDuplicates } from "./helper";
import { EMPLOYEE_DB_UNIQUES } from "./const";

const BASE_CLASS = "tk-bulk-upload__select-changes-modal-content";

const createClass = (newStr = "") => `${BASE_CLASS}${newStr}`;

const LIST_STYLES = {
    parent: {
        maxWidth: "20rem",
        minWidth: "15rem"
    }
};

const NOT_EDITABLE_KEYS = []; // add keys if we want to prevent editing i.e: "employee.residenceID"

function SelectChangesModal({ open, onChange, onFinish, data = {} }) {
    const countries = useFetchCountries();
    const getNationality = (nationality) => countries.find((ctr) => trimToLowerJoin(ctr.cca2) == trimToLowerJoin(nationality)) || "";

    const keys = Object.keys(
        data.duplicates
            .map((d) =>
                flattenObject(
                    Object.entries(d)
                        .filter(([, val]) => val.changes)
                        .reduce((prev, [key, value]) => ({ ...prev, [key]: value.changes }), {})
                )
            )
            .reduce((prev, curr) => ({ ...prev, ...curr }), {})
    );

    keys.sort((a, b) =>
        customSortKeys(
            a,
            b,
            EMPLOYEE_DB_UNIQUES.map((uk) => `employee.${uk}`)
        )
    );

    const defaultOriginal = createObjectFromKeys(
        keys,
        data.duplicates
            .map((d) =>
                flattenObject(
                    Object.entries(d)
                        .filter(([, val]) => val.original)
                        .reduce((prev, [key, value]) => ({ ...prev, [key]: value.original }), {})
                )
            )
            .reduce((prev, curr) => ({ ...prev, ...curr }), {})
    );

    const [form, setForm] = useState({
        duplicates: [],
        id: null,
        original: null,
        selected: defaultOriginal,
        keepOriginal: false,
        ...data
    });

    const updateForm = (newForm = {}) => setForm({ ...form, ...newForm });

    const handleSave = async () => {
        const clonedform = cloneDeep(form);
        const selected = clonedform.selected || {};
        for (const key in selected) {
            if (Object.hasOwnProperty.call(selected, key)) {
                const value = selected[key];
                if (FALSE_VALUES.includes(value)) {
                    delete selected[key];
                }
            }
        }
        const status = getStatusFromDuplicates(clonedform.duplicates);
        clonedform.mixed = status.key === STATUS.MIXED.key;
        clonedform.keepOriginal = status.key === STATUS.ORIGINAL.key;
        clonedform.selected = selected;
        clonedform.modified = clonedform.modified || !clonedform.keepOriginal;
        typeof onFinish === "function" && onFinish(clonedform);
    };

    const handleListChange = (accepted, rowNum, key, checked) => {
        let clonedoriginal = cloneDeep(form.selected);
        let cloneduplicates = cloneDeep(form.duplicates);
        let idx = cloneduplicates.findIndex((cd) => cd.rowNum === rowNum);

        const isIndividualCheck = !!key;

        //  manipulate accepted property which determines if the current component property is checked
        cloneduplicates = cloneduplicates.map((cud, cidx) => {
            if (!cud.accepted) {
                cud.accepted = {};
            }

            const changes = flattenObject(
                Object.entries(cud)
                    .map(([key, c]) => ({ [key]: c.changes }))
                    .reduce((prev, curr) => ({ ...prev, ...curr }), {})
            );

            const changesKeys = Object.keys(changes);

            delete changes.rowNum;
            delete changes.duplicateBy;
            delete changes.accepted;

            // if individual check
            if (isIndividualCheck) {
                // if the same changes value is found on all duplicates we need to set them all as accepted
                if (checked && isValid(changes[key]) && isValid(accepted[key]) && idx === cidx) {
                    cud.accepted[key] = accepted[key];
                } else {
                    delete cud.accepted[key];
                }
            }
            // if all is checked
            else {
                Object.entries(accepted).forEach(([k, value]) => {
                    // we compare the accepted and changes properties for check all and uncheck all using the accepted key value pair
                    if (checked && k && isValid(value) && isValid(changes[k]) && idx === cidx) {
                        cud.accepted[k] = value;
                    } else {
                        delete cud.accepted[k];
                    }
                });
                changesKeys.forEach((ck) => !accepted[ck] && delete cud.accepted[ck]);
            }
            return cud;
        });

        // assign the key
        cloneduplicates[idx].accepted = accepted;
        // collect all the keys first of the duplicate objects
        const acceptedKeys = cloneduplicates.map((cd) => cd.accepted).reduce((prev, curr) => ({ ...prev, ...curr }), {});
        // iterate the collected keys so that we get the updated selected keys and if not found in the accepted keys we use the default original value
        for (const key in defaultOriginal) {
            if (Object.hasOwnProperty.call(defaultOriginal, key)) {
                const item = defaultOriginal[key];
                if (key in acceptedKeys) {
                    const currentValue = acceptedKeys[key];
                    // if value is a falsy we dont allow it and use the original value instead
                    if (isValid(currentValue)) {
                        clonedoriginal[key] = currentValue;
                    } else {
                        clonedoriginal[key] = item;
                    }
                } else {
                    clonedoriginal[key] = item;
                }
            }
        }

        updateForm({
            duplicates: cloneduplicates,
            selected: clonedoriginal
        });
    };

    const transformDuplicatesToSelectSameValue = useMemo(() => {
        const duplicates = cloneDeep(form.duplicates);
        const selected = form.selected;
        return duplicates.map((duplicate) => {
            duplicate.fadedUncheckedKeys = [];

            if (!duplicate.accepted) {
                duplicate.accepted = {};
            }
            const changes = flattenObject(
                Object.entries(duplicate)
                    .map(([key, c]) => ({ [key]: c.changes }))
                    .reduce((prev, curr) => ({ ...prev, ...curr }), {})
            );
            delete changes.rowNum;
            delete changes.duplicateBy;
            delete changes.accepted;
            // if the same changes value is found on all duplicates we need to set them all as accepted so that they are all selected on select changes
            Object.entries(selected).forEach(([k, val]) => {
                // we compare the accepted and changes properties for check all and uncheck all using the accepted key value pair
                if (k && isValid(val) && isValid(changes[k]) && val.toString() === changes[k].toString()) {
                    duplicate.fadedUncheckedKeys.push(k);
                }
            });
            return duplicate;
        });
    }, [JSON.stringify(form.duplicates), form.selected]);

    const createDuplicateByValueFromOriginal = (duplicateBy) => {
        const isName = duplicateBy.includes("first_name");
        if (isName) {
            const keys = duplicateBy.split("+").map((key) => form.original[key]);
            return createFullName(keys.shift(), keys.pop());
        }
        return form.original[duplicateBy];
    };

    return (
        <Modal
            title={
                <div className="flex center column">
                    <span>Employee - {createFullName(form.original.first_name, form.original.last_name)}</span>
                    <span>(Select Changes)</span>
                </div>
            }
            open={open}
            onChange={onChange}
            onSave={handleSave}
            disableSave={isEqual(form, { ...data, selected: form.selected })}
            styles={{
                content: {
                    width: "95vw",
                    maxWidth: "100rem"
                },
                body: { overflow: "auto" },
                form: { padding: "0 5rem" },
                footer: { paddingTop: "1rem" }
            }}
            saveLabel="Confirm Changes"
            hasHeaderStyle
            isForm
        >
            <div className={createClass()}>
                <div className={createClass("__inner")}>
                    <AdvanceList
                        head={{
                            extra: (
                                <div className="flex align-center gap-03 align-center" style={{ height: "1rem" }}>
                                    <div className="flex align-center fade semi-bold gap-03">Employee ID :</div>
                                    <span className="bold primary-color">{data.original.generatedID}</span>
                                    <Tooltip message="System Generated ID" isIcon />
                                </div>
                            ),
                            render: (value) => {
                                value = sanitizeWords(value.split(".").pop(), "_", false);
                                return (
                                    <span className="text-ellipsis" style={{ fontWeight: "bold" }} title={value}>
                                        {value}
                                    </span>
                                );
                            }
                        }}
                        styles={{ minWidth: "7rem" }}
                        data={keys.map((k) => (k.includes("department_code") ? "department" : k))}
                        isArray
                    />
                    <AdvanceList
                        head={{
                            extra: <div></div>,
                            label: () => (
                                <div className={createClass("__list__head")} style={{ fontWeight: "bold" }}>
                                    Original
                                </div>
                            ),
                            render: (value, key) => {
                                value = Array.isArray(value) ? value.map((val) => val.toUpperCase()).join(", ") : value;
                                const isNationality = key == "employee.nationality";
                                if (isNationality) {
                                    const nationality = getNationality(value);
                                    value = (nationality && (nationality.demonyms?.eng?.m || nationality?.name?.common)) || value;
                                }
                                const isNotEditable = NOT_EDITABLE_KEYS.includes(key);
                                return (
                                    <div className="flex align-center fade w100">
                                        <span className="text-ellipsis semi-bold" title={sanitizeWords(value)} style={{ maxWidth: "13rem" }}>
                                            {sanitizeWords(value)}
                                        </span>
                                        {isNotEditable && (
                                            <>
                                                &nbsp;
                                                <span className="fade small-font">(Update Not Allowed)</span>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                        }}
                        data={createObjectFromKeys(keys, defaultOriginal, defaultOriginal)}
                        styles={LIST_STYLES}
                    />
                    <AdvanceList
                        head={{
                            extra: <div></div>,
                            label: () => (
                                <div className={createClass("__list__head")} style={{ fontWeight: "bold" }}>
                                    Selected{" "}
                                    <Tag className={getStatusFromDuplicates(form.duplicates).color}>
                                        {getStatusFromDuplicates(form.duplicates).label}
                                    </Tag>
                                </div>
                            ),
                            render: (value, key) => {
                                const isNationality = key === "employee.nationality";
                                const clonedDefaultOriginal = cloneDeep(defaultOriginal);

                                const toNormalizeValue = (v) => {
                                    return Array.isArray(v) ? v.map((val) => val.toUpperCase()).join(", ") : typeof v !== "number" ? v || "" : v;
                                };

                                const getNationalityValue = (cca2Code) => {
                                    const nationality = getNationality(cca2Code);
                                    return (nationality && (nationality.demonyms?.eng?.m || nationality?.name?.common)) || cca2Code;
                                };

                                value = toNormalizeValue(value);
                                clonedDefaultOriginal[key] = toNormalizeValue(clonedDefaultOriginal[key]);

                                if (isNationality && value) {
                                    value = getNationalityValue(value);
                                    clonedDefaultOriginal[key] = getNationalityValue(clonedDefaultOriginal[key]);
                                }

                                const isNotEditable = NOT_EDITABLE_KEYS.includes(key);

                                const isOriginal =
                                    !isNotEditable &&
                                    clonedDefaultOriginal[key] &&
                                    isEqual(trimToLowerJoin(clonedDefaultOriginal[key].toString()), trimToLowerJoin(value.toString()));

                                return (
                                    <div className="flex align-center w100">
                                        {isOriginal && (
                                            <>
                                                <span className="fade small-font">(Original)</span>
                                                &nbsp;
                                            </>
                                        )}
                                        {isNotEditable && (
                                            <>
                                                <span className="fade small-font">(Update Not Allowed)</span>
                                                &nbsp;
                                            </>
                                        )}
                                        <span
                                            className="text-ellipsis semi-bold"
                                            title={sanitizeWords(value)}
                                            style={isOriginal ? { maxWidth: "13rem" } : {}}
                                        >
                                            {sanitizeWords(value)}
                                        </span>
                                    </div>
                                );
                            }
                        }}
                        data={createObjectFromKeys(
                            keys,
                            flattenObject(Object.entries(form.selected).reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})),
                            defaultOriginal
                        )}
                        styles={LIST_STYLES}
                    />
                    {transformDuplicatesToSelectSameValue.map((duplicate, idx) => {
                        return (
                            <AdvanceList
                                key={idx}
                                head={{
                                    extra: <div className="fade bold">Row # / Duplicate By</div>,
                                    label: () => (
                                        <div className={createClass("__list__head")}>
                                            <span className="primary-color" style={{ fontWeight: "bold" }}>
                                                Row {duplicate.rowNum}
                                            </span>
                                            <Tag className="red" tooltip={{ message: createDuplicateByValueFromOriginal(duplicate.duplicateBy) }}>
                                                <span style={{ textTransform: "uppercase" }}>
                                                    {sanitizeWords(duplicate.duplicateBy.replace("ID", "_ID"), "_")}
                                                </span>
                                            </Tag>
                                        </div>
                                    ),
                                    render: (value, key, config) => {
                                        let className = "text-ellipsis semi-bold flex gap-03 align-center ";
                                        value = Array.isArray(value) ? value.map((val) => val.toUpperCase()).join(", ") : value;
                                        const isNationality = key == "employee.nationality";
                                        if (isNationality) {
                                            const nationality = getNationality(value);
                                            value = (nationality && (nationality.demonyms?.eng?.m || nationality?.name?.common)) || value;
                                        }
                                        if (config.isDisabled) className += "fade ";
                                        return (
                                            <span className={className.trim()}>
                                                {value ? sanitizeWords(value.toString()) : value}
                                                {config.isDisabled && <Tooltip message={`"${value}" is already taken by other employee.`} isIcon />}
                                            </span>
                                        );
                                    }
                                }}
                                data={createObjectFromKeys(
                                    keys,
                                    flattenObject(
                                        Object.entries(duplicate)
                                            .filter(([, val]) => val.changes)
                                            .reduce((prev, [key, value]) => ({ ...prev, [key]: value.changes }), {})
                                    )
                                )}
                                onChange={(keys, key, checked) => handleListChange(keys, duplicate.rowNum, key, checked)}
                                values={Object.keys(duplicate?.accepted || {})}
                                styles={LIST_STYLES}
                                // we assume the all the fields from "notAllowedChangesKeyToUse" is from employee object
                                disabledKeys={NOT_EDITABLE_KEYS.concat(duplicate.notAllowedChangesKeyToUse.map((k) => "employee." + k))}
                                fadedUncheckedKeys={duplicate.fadedUncheckedKeys}
                                hasCheckbox
                            />
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
}

SelectChangesModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onFinish: PropTypes.func
};

export default SelectChangesModal;
