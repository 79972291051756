import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import EmployeesTableLazy from "./EmployeesTableLazy";
import { ASSIGN_TYPE } from "./const";
import Tabs from "../../../common/components/extra/tabs/TabsV2";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";

const BASE_CLASS = "tk-employees__assign-modal";

const createClass = (newStr = "") => `${BASE_CLASS}${newStr}`;

const VALIDITY_KEYS = ["residenceID", "visaID", "passportID", "contract", "labor_card"];

const hasValue = (valdity = {}) => (valdity?.warning || 0) > 0 || (valdity?.expired || 0) > 0;

function ExpiringEmployeesModal({ open, onClose }) {
    const user = useAppSelector(selectUser);
    const summary = user.employeeFilesValidity || {};

    const hasInsuranceWarningExpired = useMemo(() => {
        return hasValue(summary.insurances);
    }, [summary.insurances]);

    const hasDocumentWarningExpired = useMemo(() => {
        return VALIDITY_KEYS.some((key) => hasValue(summary[key]));
    }, [summary]);

    return (
        <>
            <Modal
                title={<div className="flex center">Expiring/Expired Employee Documents/Insurance</div>}
                open={open}
                onClose={onClose}
                styles={{
                    content: { width: "70vw", height: "max-content", minWidth: "35rem" },
                    body: { overflow: "auto" },
                    form: { padding: "0 5rem" },
                    footer: { paddingTop: "1rem" }
                }}
                hasHeaderStyle
            >
                <div className={createClass()}>
                    <div className={createClass("__inner flex column gap-1")}>
                        <Tabs id="expiring-employees" useStorage hasBackground>
                            <EmployeesTableLazy
                                hash="expiring-documents"
                                label="Documents"
                                type={ASSIGN_TYPE.EXPIRING}
                                minHeight="50vh"
                                hide={!hasDocumentWarningExpired} // not used
                                isExpiringWideUI
                                readOnly
                            />
                            <EmployeesTableLazy
                                hash="expiring-insurance"
                                label="Insurance"
                                type={ASSIGN_TYPE.INSURANCE_EXPIRING}
                                minHeight="50vh"
                                hide={!hasInsuranceWarningExpired} // not used
                                isExpiringWideUI
                                readOnly
                            />
                        </Tabs>
                    </div>
                </div>
            </Modal>
        </>
    );
}

ExpiringEmployeesModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default ExpiringEmployeesModal;
