import React, { useMemo, useRef, useState } from "react";
import isNumber from "lodash/isNumber";
import { ReactComponent as WarningSVG } from "../../../assets/images/warning-icon.svg";
import StepWizard, { STEP_CHANGE_EVENT_TYPE } from "../../../common/components/extra/stepWizard/StepWizard";
import ExcelFile from "./BulkUploadExcelFile";
import UploadFiles from "./BulkUploadFiles";
import CheckDuplicates from "./BulkUploadCheckDuplicates";
import Result from "./BulkUploadResult";
import { useBulkProcessEmployees } from "./hooks";
import { createPromiseToast } from "../../../common/utilities/helper";
import { STEP } from "./const";
import BulkIcon from "./BulkIcon";
import { cloneDeep } from "lodash";

function BulkUpload() {
    const [result, setResult] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [isProcessingFileUpload, setIsProcessingFileUpload] = useState(false);

    const [processEmployees, isProcessingEmployees] = useBulkProcessEmployees();

    const excelUploadRef = useRef(null);
    const folderUploadRef = useRef(null);

    const uploadRefBtn = folderUploadRef?.current?.processUploadsRef?.current;
    const hasEmptyUploadedFiles = !!(!result?.[STEP.STEP_3.idx] || !result[STEP.STEP_3.idx]?.batch?.items?.length);
    const processToken = result?.[STEP.STEP_1.idx]?.token;

    const startFileUploadProcessing = () => setIsProcessingFileUpload(true);
    const stopFileUploadProcessing = () => setIsProcessingFileUpload(false);

    const updateResult = (data, idx) => {
        setResult({
            ...result,
            [idx]: data
        });
    };

    const reset = (indexToReset) => {
        if (isNumber(indexToReset)) {
            updateResult(null, indexToReset);
            setError({ ...error, [indexToReset]: null });
            setWarning({ ...warning, [indexToReset]: null });
        } else {
            setResult(null);
            setError(null);
            setWarning(null);
        }
    };

    /**
     * @param {Number} index
     * @param {String} eventType
     * @param {Function} cb // this is the onstart and onfinish props for each step we can use this if we want to attach a function and wait for it before changing the active index
     */
    const handleChange = async (index, eventType) => {
        const isGoingToStep3 = index == STEP.STEP_3.idx;
        const isGoingToStep4 = index == STEP.STEP_4.idx;

        switch (eventType) {
            case STEP_CHANGE_EVENT_TYPE.NEXT:
                {
                    if (isGoingToStep3) {
                        try {
                            await createPromiseToast(() => processEmployees(processToken, result[STEP.STEP_2.idx]), {
                                render: {
                                    pending: () => "Creating/Updating employees, please wait...",
                                    success: (data) => {
                                        updateResult(
                                            {
                                                ...(result[STEP.STEP_2.idx] || {}),
                                                ...(data?.data?.summary || {}),
                                                keepOriginalRecords: result[STEP.STEP_2.idx].employees.filter((emp) => emp.keepOriginal).length,
                                                employees: result[STEP.STEP_2.idx].employees.map((emp) => ({
                                                    ...emp,
                                                    id: emp.original.id, // set to actual id not the table ID so that we fetch the correct data when we view
                                                    selected: { ...emp.selected }
                                                })),
                                                newRecords: data?.data?.summary?.newRecords
                                            },
                                            STEP.STEP_2.idx
                                        );
                                        return "Employees have been successfully created/updated.";
                                    },
                                    error: (data) => {
                                        setError({
                                            ...error,
                                            [STEP.STEP_2.idx]: (
                                                <BulkIcon
                                                    img={<WarningSVG color="#F33636" style={{ width: "5rem" }} />}
                                                    text={
                                                        <span style={{ maxWidth: "17rem", textAlign: "center", display: "inline-block" }}>
                                                            {data?.message || "Error Occured."}
                                                        </span>
                                                    }
                                                />
                                            )
                                        });
                                        return data?.message || "Error Occured.";
                                    }
                                }
                            });
                        } catch (error) {
                            setCurrentIndex(STEP.STEP_2.idx);
                            // we need to prevent from proceeding to next if there is an error
                            return;
                        }
                    } else if (isGoingToStep4 && !hasEmptyUploadedFiles) {
                        uploadRefBtn?.click?.();
                        return "Starting the file upload process. Please do not refresh the page to avoid unsuccessful uploads.";
                    }
                }
                break;
            case STEP_CHANGE_EVENT_TYPE.BACK:
                reset(currentIndex);
                break;
            case STEP_CHANGE_EVENT_TYPE.SKIP:
                break;
            case STEP_CHANGE_EVENT_TYPE.RETRY:
                reset();
                break;
            default:
                break;
        }
        setCurrentIndex(index);
    };

    /**
     * @param {Object} data
     * @param {Array} data.employees
     * @param {Boolean} data.checking
     * @param {Object} data.summary
     * @param {Number} data.summary.toBeInserted
     * @param {Number} data.summary.toBeUpdated
     */
    const handleDuplicateChange = (data) => {
        updateResult(data, STEP.STEP_2.idx);
    };

    const handleFileUploadStart = () => {
        startFileUploadProcessing();
    };

    const handleFileUploadFinish = async (result) => {
        stopFileUploadProcessing();
        // just add a waiting time so that we can see the loading finish
        updateResult(result, STEP.STEP_4.idx);
        setCurrentIndex(STEP.STEP_4.idx);
    };

    const employeesForFileUpload = useMemo(() => {
        const step2Result = cloneDeep(result?.[STEP.STEP_2.idx] || {});
        const duplicates = step2Result?.employees || []; // employees attribute here is duplicates and new records is not included

        let temp = [
            ...(step2Result?.newRecords || []).map((record) => record.employee),
            ...(step2Result?.noChangesRecords || []).map((record) => record.original)
        ];

        if (duplicates.length) {
            // create an employees array where the selected and original is combined
            temp = temp.concat(
                duplicates.map((employee) => {
                    const original = cloneDeep(employee.original);
                    // we only care about merging the employee object since it is always the one tha contains the unique key for file upload
                    const selected = employee.selected;
                    const onlyEmployeeSelectedKeys = Object.keys(selected || {}).filter((employeeSelectedKeys) =>
                        employeeSelectedKeys.includes("employee.")
                    );

                    for (const key of onlyEmployeeSelectedKeys) {
                        const newKey = key.split(".").pop();
                        if (original[newKey]) {
                            original[newKey] = employee.selected[key];
                        }
                    }
                    return original;
                })
            );
        }

        return temp;
    }, [JSON.stringify(result?.[STEP.STEP_2.idx], null, 2)]);

    return (
        <div className="tk-bulk-upload">
            <StepWizard
                currentIndex={currentIndex}
                onChange={handleChange}
                error={error}
                warning={warning}
                unSkippableSteps={Object.values(STEP)
                    .filter((step) => step.required)
                    .map((step) => step.idx)}
                disabledNext={currentIndex != STEP.STEP_3.idx && !result?.[currentIndex]}
                isProcessing={isProcessingEmployees || isProcessingFileUpload}
                combinedNextSkipIndexes={[STEP.STEP_3.idx]}
                completedRetryLabel="Reupload"
                nextTooltip={{
                    [STEP.STEP_2.idx]: (
                        <div className="danger-color">
                            This action will insert new employees and update existing ones based on the selected changes. Please review your choices
                            carefully and confirm before proceeding, as this process cannot be undone.
                        </div>
                    ),
                    [STEP.STEP_3.idx]: !hasEmptyUploadedFiles && (
                        <div className="danger-color">
                            This action will update employee files based on the folder name you have used. Please review your choices carefully and
                            confirm the folder name if it trully exist or is supported before proceeding, as this process is irreversible.
                        </div>
                    )
                }}
                nextLabel={currentIndex === STEP.STEP_2.idx ? "Confirm Duplicates & Next" : "Next"}
                noBackButtonIndexes={[STEP.STEP_3.idx]}
            >
                <ExcelFile
                    ref={excelUploadRef}
                    value={result?.[STEP.STEP_1.idx]?.batch}
                    name={STEP.STEP_1.name}
                    onError={(errObj) => setError({ ...error, [STEP.STEP_1.idx]: errObj })}
                    onWarning={(warningObj) => setWarning({ ...warning, [STEP.STEP_1.idx]: warningObj })}
                    error={error?.[STEP.STEP_1.idx]}
                    warning={warning?.[STEP.STEP_1.idx]}
                    onRemoveUploads={reset}
                    onSuccess={(result) => updateResult(result, STEP.STEP_1.idx)}
                />
                <CheckDuplicates
                    name={STEP.STEP_2.name}
                    value={result?.[STEP.STEP_2.idx]}
                    onChange={handleDuplicateChange}
                    onError={(message) => setError({ ...error, [STEP.STEP_2.idx]: message })}
                    onWarning={(message) => setWarning({ ...warning, [STEP.STEP_2.idx]: message })}
                    error={error?.[STEP.STEP_2.idx]}
                    warning={warning?.[STEP.STEP_2.idx]}
                    processToken={processToken}
                />
                <UploadFiles
                    ref={folderUploadRef}
                    value={result?.[STEP.STEP_3.idx]?.batch}
                    employees={employeesForFileUpload}
                    name={STEP.STEP_3.name}
                    onError={(message) => setError({ ...error, [STEP.STEP_3.idx]: message })}
                    onWarning={(message) => setWarning({ ...warning, [STEP.STEP_3.idx]: message })}
                    error={error?.[STEP.STEP_3.idx]}
                    warning={warning?.[STEP.STEP_3.idx]}
                    onUploadStart={handleFileUploadStart}
                    onUploadAdd={(batch) => updateResult({ ...(result?.[STEP.STEP_3.idx] || {}), batch }, STEP.STEP_3.idx)}
                    onSuccess={handleFileUploadFinish}
                    onRemoveUploads={() => reset(STEP.STEP_3.idx)}
                    processToken={processToken}
                />
                <Result name={STEP.STEP_4.name} ignoredRows={result?.[STEP.STEP_1.idx]?.ignoredEmployees} result={result} />
            </StepWizard>
        </div>
    );
}

export default BulkUpload;
