import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import FormSection from "../../../common/components/extra/FormSection";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { renderNA, sanitizeWords, toProperTimezoneConversion } from "../../../common/utilities/helper";
import { DATE_STATUS, EMPLOYEE_INSURANCE_PLAN, EMPLOYEE_INSURANCE_STATUS, EMPLOYEE_INSURANCE_TYPE } from "./const";
import { READABLE_DATE } from "../../../common/utilities/const";
import { getInsuranceDateStatus } from "./helper";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";

const COMMON_INPUT_PROPS = { type: INPUT_TYPE.TEXT, readOnly: true };

function ViewInsuranceModal({ open, onClose, data }) {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;

    const toReadableDate = (date) => date && toProperTimezoneConversion(date, timezone).format(READABLE_DATE);

    const dateStatus = useMemo(() => {
        return getInsuranceDateStatus(data, timezone);
    }, [data?.coverage_expiration]);

    const isExpiringInsurance = DATE_STATUS.EXPIRING === dateStatus.value;
    const isExpiredInsurance = DATE_STATUS.EXPIRED === dateStatus.value;
    const isNormalStatus = dateStatus.value === DATE_STATUS.NORMAL;

    return (
        <Modal
            title={`${sanitizeWords(data.type, "_")} Insurance`}
            open={open}
            onClose={onClose}
            styles={{ title: { textAlign: "center" }, content: {} }}
            small
        >
            <div className="tk-employees__modal-content-view" style={{ padding: "1rem" }}>
                <FormSection style={{ minWidth: "25rem" }} nohead>
                    <div className="flex column" style={{ margin: ".5rem 0" }}>
                        <Input
                            label="Company Name"
                            renderValue={data.company_name ? sanitizeWords(data.company_name) : renderNA()}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Card Number"
                            renderValue={data.card_number ? sanitizeWords(data.card_number).toUpperCase() : renderNA()}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input label="Plan" renderValue={sanitizeWords(data.plan, "_")} {...COMMON_INPUT_PROPS} />
                        <Input label="Start Date" renderValue={toReadableDate(data.coverage_start)} {...COMMON_INPUT_PROPS} />
                        <Input
                            label="Valid Until"
                            renderValue={
                                <div className="flex gap-05 align-center">
                                    {!isNormalStatus && (
                                        <WarningColorSwitcher
                                            style={{ width: "1rem" }}
                                            tooltip={{
                                                message: isExpiringInsurance ? "Is Expiring" : "Is Expired",
                                                className: "flex align-center"
                                            }}
                                            danger={isExpiredInsurance}
                                            warning={isExpiringInsurance}
                                        />
                                    )}
                                    <span
                                        className={isExpiredInsurance ? "danger-color" : isExpiringInsurance ? "warning-color" : "success-color"}
                                        style={{ marginTop: "2px" }}
                                    >
                                        {toReadableDate(data.coverage_expiration)}
                                    </span>
                                </div>
                            }
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input label="Status" renderValue={sanitizeWords(data.status, "_")} {...COMMON_INPUT_PROPS} />
                    </div>
                </FormSection>
            </div>
        </Modal>
    );
}

ViewInsuranceModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_TYPE)),
        status: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_STATUS)),
        plan: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_PLAN)),
        card_number: PropTypes.string,
        company_name: PropTypes.string,
        coverage_start: PropTypes.string,
        coverage_expiration: PropTypes.string
    }),
    onClose: PropTypes.func
};

export default ViewInsuranceModal;
