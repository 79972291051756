import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/employee";

const END_POINTS = {
    loadEmployees: { method: "post", path: "load-all" },
    loadEmployeesLazy: { method: "post", path: "load-all-lazy" },
    createEmployee: { method: "post", path: "create" },
    deleteEmployee: { method: "delete", path: "delete" },
    updateEmployee: { method: "put", path: "update" },
    updateEmployeeFiles: { method: "put", path: "update-file-fields" },
    updateEmployeeInsurances: { method: "put", path: "update-insurances" },
    assignEmployeePosition: { method: "put", path: "assign-employee-position" },
    updateEmployeeDept: { method: "put", path: "update-dept-fields" },
    employeeDetails: { method: "get", path: "get-details" },
    employeeUploadFiles: { method: "put", path: "upload-files" },
    employeeUploadBulk: { method: "put", path: "upload-bulk" },
    employeeFile: { method: "get", path: "file" },
    loadEmployeeRoles: { method: "get", path: "roles" },
    assignEmployees: { method: "post", path: "assign" },
    resetDevice: { method: "post", path: "reset-device" },
    // bulk steps
    checkDuplicate: { method: "get", path: "bulk-steps/check-dup" },
    processEmployees: { method: "post", path: "bulk-steps/process-employees" }
};

export const employeesAPI = apiSlice.injectEndpoints({
    reducerPath: "employeesAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    if (queryParams.formData) conf.formData = true;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useLoadEmployeesMutation,
    useLoadEmployeesLazyMutation,
    useCreateEmployeeMutation,
    useDeleteEmployeeMutation,
    useUpdateEmployeeMutation,
    useUpdateEmployeeDeptMutation,
    useAssignEmployeePositionMutation,
    useUpdateEmployeeFilesMutation,
    useUpdateEmployeeInsurancesMutation,
    useEmployeeDetailsMutation,
    useEmployeeUploadFilesMutation,
    useEmployeeUploadBulkMutation,
    useEmployeeFileMutation,
    useLoadEmployeeRolesMutation,
    useAssignEmployeesMutation,
    useResetDeviceMutation,
    useCheckDuplicateMutation,
    useProcessEmployeesMutation
} = employeesAPI;

export default employeesAPI.reducer;
