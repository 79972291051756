import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SORT_ORDER } from "../../../utilities/const";
import { isScrollAtBottom } from "../../../utilities/helper";
import Loader from "../Loader";
import Tag from "../Tag";
import TotalRecords from "../filter/TotalRecords";
import isEqual from "lodash/isEqual";
import Checkbox from "../Checkbox";

const { ASC, DESC } = SORT_ORDER;

export const CHECKBOX_TYPE = {
    ALL: "check-all",
    INDIVIDUAL: "check-individual"
};

const CHECK_ACTION = {
    CHECKED: "checked",
    UNCHECKED: "unchecked"
};

function TableListSimple({
    uniqueKey = "id",
    data = [],
    headers = [],
    height = 35,
    small,
    onSort,
    activeSort = {
        sortBy: "createdAt",
        order: DESC
    },
    onLoadMore,
    onCheckChange,
    selected = [],
    disabledUniqueKeys = [],
    disableCheckbox,
    totalCount,
    isLoading,
    checkedByDefault,
    // style specific
    customEmptyEl,
    isLoadingMore,
    showCheckCount,
    isFixedHeight,
    isTotalRecordsLeft,
    footExtra,
    minHeight = "22rem",
    headExtra,
    footStyle
}) {
    const [checkActions, setCheckActions] = useState(
        selected.map((checkedUniqueKey) => ({
            value: checkedUniqueKey,
            action: CHECK_ACTION.CHECKED
        }))
    );

    const checked = checkActions.filter((checkAction) => checkAction.action === CHECK_ACTION.CHECKED).map((ch) => ch.value);
    const checkActionsOnlyValue = useMemo(() => checkActions.map((ch) => ch.value), [checkActions.length]);

    const checkCount = checkedByDefault ? totalCount : checked.length;
    const isAllCheckboxDisabled = disabledUniqueKeys.length === data.length;
    const isCheckedAll = checked.length === data.length;

    useEffect(() => {
        if (checkActions.length !== selected.length) {
            // for new actions mark it as checked; for existing actions just do update and make it UNCHECKED
            const newActions = selected.filter((newSelectedKey) => !checkActionsOnlyValue.includes(newSelectedKey));
            setCheckActions(
                newActions
                    .map((checkedUniqueKey) => ({
                        value: checkedUniqueKey,
                        action: CHECK_ACTION.CHECKED
                    }))
                    .concat(
                        checkActions.map((checkAction) => {
                            // need to update to unchecked if current value is not found from selected
                            if (!selected.includes(checkAction.value)) {
                                checkAction.action = CHECK_ACTION.UNCHECKED;
                            }
                            return checkAction;
                        })
                    )
            );
        }
    }, [selected.length]);

    // this targets if the table is used for lazy loading so we need to sync the checked value for new data
    useEffect(() => {
        if (checkedByDefault) {
            handleCheckChange(CHECKBOX_TYPE.ALL, true);
        }
    }, [checkedByDefault, data.length]);

    const handleScroll = (e) => {
        if (isScrollAtBottom(e.target, 100)) {
            typeof onLoadMore === "function" && onLoadMore();
        }
    };

    const handleCheckChange = (type, bool, row) => {
        if (type !== CHECKBOX_TYPE.ALL && disabledUniqueKeys.includes(row[uniqueKey])) {
            return;
        }
        let newCheckAction = checkActions;
        switch (type) {
            case CHECKBOX_TYPE.ALL: {
                newCheckAction = bool
                    ? data
                          .filter((d) => !(checkedByDefault ? [] : disabledUniqueKeys).includes(d[uniqueKey]))
                          .map((d) => ({
                              value: d[uniqueKey],
                              action: CHECK_ACTION.CHECKED
                          }))
                    : checkedByDefault
                      ? disabledUniqueKeys.map((uniqueKey) => ({
                            value: uniqueKey,
                            action: CHECK_ACTION.CHECKED
                        }))
                      : [];
                break;
            }
            case CHECKBOX_TYPE.INDIVIDUAL: {
                newCheckAction = bool
                    ? newCheckAction.concat({ value: row[uniqueKey], action: CHECK_ACTION.CHECKED })
                    : newCheckAction.filter((checkAction) => !isEqual(checkAction.value, row[uniqueKey]));
                break;
            }
            default:
                break;
        }

        setCheckActions(newCheckAction);
        onCheckChange(
            bool,
            newCheckAction.filter((ca) => ca.action === CHECK_ACTION.CHECKED).map((ca) => ca.value),
            type,
            row
        );
    };

    const handleSort = (order, col) => {
        if (col.sortKey == activeSort.sortBy && order == activeSort.order) {
            return;
        }
        typeof onSort === "function" && onSort({ sortBy: col.sortKey, order });
    };

    const parentStyle = useMemo(() => {
        const temp = {
            maxHeight: `calc(100vh - ${height || 35}rem)`,
            minHeight: minHeight || "22rem"
        };
        if (isFixedHeight) {
            temp.height = temp.maxHeight;
        }
        if (isLoading) {
            temp.opacity = 0.8;
        }
        return temp;
    }, [height]);

    const rowStyle = useMemo(() => {
        const totalHeaders = headers.length;
        const commonWidth = 100 / totalHeaders + "%";
        const getwidths = headers.map((h) => h.width || commonWidth);
        return {
            gridTemplateColumns: getwidths.join(" ")
        };
    }, [headers]);

    return (
        <>
            {headExtra}
            <div className={"table-list" + (small ? " small" : "")} style={parentStyle} onScroll={handleScroll}>
                <div className="table-list__header">
                    <div className="table-list__item row head" style={rowStyle}>
                        {headers.map((col, i) => {
                            return (
                                <div key={i} className="table-list__item__col head">
                                    <div
                                        className={`w100 col-value ${(col.isCheckbox && " has-checkbox") || ""}  ${
                                            (col.sortKey && " has-sort") || ""
                                        }`}
                                        style={col.style || {}}
                                    >
                                        {col.sortKey ? (
                                            <div className="sort">
                                                {col.label}
                                                <div className="sort-group">
                                                    <Sorter
                                                        sortKey={col.sortKey}
                                                        activeSort={activeSort}
                                                        onChange={(order) => handleSort(order, col)}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            col.label
                                        )}
                                        {col.isCheckbox && (
                                            <Checkbox
                                                checked={isCheckedAll}
                                                onChange={(bool) =>
                                                    !isAllCheckboxDisabled && !disableCheckbox && handleCheckChange(CHECKBOX_TYPE.ALL, bool)
                                                }
                                                disabled={isAllCheckboxDisabled || disableCheckbox}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        {showCheckCount && !!checkCount && <Tag className="check-count red">{checkCount}</Tag>}
                    </div>
                </div>
                <div className="table-list__body" onScroll={handleScroll}>
                    <div className="table-list__inner">
                        {!isLoadingMore && !data.length && (
                            <div className="table-list__item row empty">{customEmptyEl || <span className="fade">No data available</span>}</div>
                        )}
                        {data.map((row, i) => (
                            <div key={i} className="table-list__item row" style={rowStyle}>
                                {headers.map((col, ci) => {
                                    const isDisabled = disabledUniqueKeys.includes(row[uniqueKey]) || disableCheckbox;
                                    return (
                                        <div key={col.key + i + ci} className="table-list__item__col">
                                            <div className={"col-value" + ((col.isCheckbox && " has-checkbox") || "")} style={col.style || {}}>
                                                {typeof col.render === "function"
                                                    ? col.render(row, i, checked)
                                                    : typeof row === "object"
                                                      ? row?.[col.key] || ""
                                                      : ""}
                                                {col.isCheckbox && (
                                                    <Checkbox
                                                        checked={checked.includes(row[uniqueKey])}
                                                        onChange={(bool) => !isDisabled && handleCheckChange(CHECKBOX_TYPE.INDIVIDUAL, bool, row)}
                                                        disabled={isDisabled}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                        {isLoadingMore && (
                            <div className="table-list__item row empty">
                                <div className="flex center">
                                    <Loader style={{ width: "2rem" }} relative />
                                    <span className="fade">Getting data...</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex gap-1 w100" style={{ marginLeft: "auto", justifyContent: "space-between", ...(footStyle || {}) }}>
                <div className="flex center" style={{ order: isTotalRecordsLeft ? 2 : 1 }}>
                    {footExtra}
                </div>
                {!!totalCount && <TotalRecords value={totalCount} style={{ order: isTotalRecordsLeft ? 1 : 2 }} />}
            </div>
        </>
    );
}

const Sorter = ({ sortKey, activeSort, onChange }) => {
    const currentActiveSort = sortKey && activeSort.sortBy === sortKey;

    const getColActiveOrder = (activeSort, order) => {
        return currentActiveSort && activeSort.order === order;
    };

    if (currentActiveSort && activeSort.order !== ASC) {
        return <ArrowUpIcon className={(getColActiveOrder(activeSort, ASC) && "active") || ""} onClick={() => onChange(ASC)} />;
    }
    return <ArrowDownIcon className={(getColActiveOrder(activeSort, DESC) && "active") || ""} onClick={() => onChange(DESC)} />;
};

Sorter.propTypes = {
    sortKey: PropTypes.string,
    onChange: PropTypes.func,
    activeSort: PropTypes.shape({
        sortBy: PropTypes.string,
        order: PropTypes.oneOf(Object.values(SORT_ORDER))
    })
};

TableListSimple.propTypes = {
    data: PropTypes.array,
    selected: PropTypes.array,
    disabledUniqueKeys: PropTypes.array,
    checkedByDefault: PropTypes.bool,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            sortKey: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            width: PropTypes.string,
            render: PropTypes.func,
            style: PropTypes.object,
            isCheckbox: PropTypes.bool
        })
    ),
    checked: PropTypes.object,
    height: PropTypes.number,
    small: PropTypes.bool,
    onSort: PropTypes.func,
    onLoadMore: PropTypes.func,
    onCheckChange: PropTypes.func,
    activeSort: PropTypes.shape({
        sortBy: PropTypes.string,
        order: PropTypes.oneOf(Object.values(SORT_ORDER))
    }),
    uniqueKey: PropTypes.string,
    customEmptyEl: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    isLoadingMore: PropTypes.bool,
    showCheckCount: PropTypes.bool,
    isFixedHeight: PropTypes.bool,
    isLoading: PropTypes.bool,
    disableCheckbox: PropTypes.bool,
    isTotalRecordsLeft: PropTypes.bool,
    totalCount: PropTypes.any,
    footExtra: PropTypes.any,
    headExtra: PropTypes.any,
    minHeight: PropTypes.string,
    footStyle: PropTypes.object
};

export default TableListSimple;
