import React, { useState, forwardRef, Suspense, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import NextIcon from "@mui/icons-material/KeyboardArrowRight";
import BackIcon from "@mui/icons-material/KeyboardArrowLeft";
import ReplayIcon from "@mui/icons-material/Replay";
import SkipRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NormalCircleIcon from "@mui/icons-material/RadioButtonUnchecked";
import ErrorIcon from "@mui/icons-material/Cancel";
import CompletedIcon from "@mui/icons-material/Verified";
import Button from "../Button";
import { createConfirmAlert } from "../../../utilities/helper";
import { STEP_TYPE } from "./const";
import Loader from "../Loader";

export const STEP_CHANGE_EVENT_TYPE = {
    NEXT: "next",
    BACK: "back",
    SKIP: "skip",
    RETRY: "retry"
};

const StepWizard = forwardRef(function StepWizard(
    {
        currentIndex = 0,
        onChange,
        children,
        error,
        warning,
        fallback: globalFallback = <div>Loading...</div>,
        unSkippableSteps = [],
        disabledNext,
        isProcessing,
        combinedNextSkipIndexes = [],
        completedRetryLabel,
        nextLabel,
        nextTooltip,
        noBackButtonIndexes = []
    },
    ref
) {
    // loading state for the current active children/step
    const [isLoading, setLoading] = useState(false);

    const totalSteps = children.length;
    const isCompleted = totalSteps - 1 === currentIndex;
    const isFirst = currentIndex <= 0;
    const hasError = error?.[currentIndex];

    const isNextDisabled = isLoading || disabledNext || hasError || isProcessing;
    const isRetryDisabled = isLoading || isProcessing;
    const isCombinedNextSkip = combinedNextSkipIndexes.includes(currentIndex);

    const isCurrentIndexIncludedInNoBackButton = noBackButtonIndexes.includes(currentIndex);

    const hasRetry = hasError || isCompleted || isCurrentIndexIncludedInNoBackButton;
    const hasBack = !isCompleted && !isFirst && !isCurrentIndexIncludedInNoBackButton;
    const hasNext = !isCompleted && !hasError;
    const hasSkip = !isCompleted && !isFirst && !unSkippableSteps.includes(currentIndex);

    useEffect(() => {
        if (isProcessing !== isLoading) {
            setLoading(isProcessing);
        }
    }, [isProcessing]);

    const startLoading = () => setLoading(true);
    const stopLoading = () => setLoading(false);

    const handleObjectChange = async (newIndex, evenType) => {
        await onChange?.(newIndex, evenType);
    };

    const nextCurrentIndex = (newEventType) => {
        if (currentIndex <= totalSteps - 1) {
            handleObjectChange(currentIndex + 1, newEventType || STEP_CHANGE_EVENT_TYPE.NEXT);
        }
    };

    const prevCurrentIdex = () => {
        if (currentIndex > 0) {
            handleObjectChange(currentIndex - 1, STEP_CHANGE_EVENT_TYPE.BACK);
        }
    };

    const handleNext = async () => {
        if (hasNext) {
            nextCurrentIndex();
        }
    };

    const handleRetry = () => {
        if (hasRetry) {
            handleObjectChange(0, STEP_CHANGE_EVENT_TYPE.RETRY);
        }
    };

    const handleSkip = async () => {
        if (hasSkip) {
            nextCurrentIndex(STEP_CHANGE_EVENT_TYPE.SKIP);
        }
    };

    return (
        <div className="tk-step-wizard">
            <div className="tk-step-wizard__header">
                <Steps
                    items={React.Children.map(children, (child) => child.props.name)}
                    currentIndex={currentIndex}
                    isLoading={isLoading}
                    error={error}
                    warning={warning}
                />
            </div>
            <div className="tk-step-wizard__content" style={isLoading ? { pointerEvents: "none", cursor: "not-allowed" } : {}}>
                {React.Children.map(children, (child, index) => {
                    if (index === currentIndex) {
                        return (
                            <Suspense fallback={child.props.fallback || globalFallback}>
                                {React.cloneElement(child, {
                                    onStart: startLoading,
                                    onFinish: stopLoading
                                })}
                            </Suspense>
                        );
                    }
                    return null;
                })}
            </div>
            <div className="tk-step-wizard__footer">
                <div className="controls">
                    {hasRetry && (
                        <Button
                            className={isCompleted ? "primary" : "danger"}
                            onClick={() =>
                                isCompleted || isFirst
                                    ? handleRetry()
                                    : createConfirmAlert({
                                          title: "Retry the process from the start?",
                                          content: "All your progress will be lost.",
                                          onConfirm: (onClose) => {
                                              handleRetry();
                                              onClose();
                                          }
                                      })
                            }
                            disabled={isRetryDisabled}
                            afterExtra={<ReplayIcon />}
                            small
                            mini
                        >
                            {isCompleted ? completedRetryLabel || "Try again" : "Retry"}
                        </Button>
                    )}
                    {hasBack && (
                        <Button
                            onClick={() => prevCurrentIdex()}
                            disabled={isLoading}
                            beforeExtra={<BackIcon style={{ width: "1.5rem" }} />}
                            small
                            mini
                        >
                            Back
                        </Button>
                    )}
                    {hasNext && (
                        <Button
                            ref={ref}
                            className="primary"
                            onClick={() =>
                                createConfirmAlert({
                                    title: "Proceed to next step?",
                                    content:
                                        nextTooltip?.[currentIndex] ||
                                        (totalSteps - 1 === currentIndex
                                            ? "Proceeding will initiate the saving process for the new changes. You won't be able to go back."
                                            : "You can still navigate back to retry the process if needed."),
                                    onConfirm: (onClose) => {
                                        handleNext();
                                        onClose();
                                    }
                                })
                            }
                            isLoading={isLoading}
                            afterExtra={<NextIcon style={{ width: "1.5rem" }} />}
                            disabled={isNextDisabled}
                            small
                            mini
                        >
                            {isLoading ? "Processing..." : isCombinedNextSkip ? "Next/Skip" : nextLabel || "Next"}
                        </Button>
                    )}
                    {hasSkip && !isCombinedNextSkip && (
                        <Button
                            ref={ref}
                            className="primary"
                            onClick={() =>
                                createConfirmAlert({
                                    title: "Skip step?",
                                    content:
                                        totalSteps - 1 === currentIndex
                                            ? "This will skip the current process and will proceed to the next step."
                                            : "You can still navigate back to retry the process if needed.",
                                    onConfirm: (onClose) => {
                                        handleSkip();
                                        onClose();
                                    }
                                })
                            }
                            afterExtra={<SkipRightIcon />}
                            isLoading={isLoading}
                            small
                            mini
                        >
                            Skip
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
});

function StepLine({ step, type = STEP_TYPE.NORMAL, hasNext, loading, active, styles = {}, processing }) {
    const renderInfo = useMemo(() => {
        let classname = "",
            render = "";

        switch (type) {
            default:
            case STEP_TYPE.NORMAL: {
                render = <NormalCircleIcon />;
                if (active) {
                    classname = "checked";
                }
                break;
            }
            case STEP_TYPE.CHECK: {
                render = <CheckCircleIcon />;
                classname = "checked";
                break;
            }
            case STEP_TYPE.COMPLETED: {
                render = <CompletedIcon />;
                classname = "completed";
                break;
            }
            case STEP_TYPE.ERROR: {
                render = <ErrorIcon />;
                classname = "error";
                break;
            }
            case STEP_TYPE.WARNING: {
                render = <ErrorIcon className="warning-color" />;
                classname = "warning";
                break;
            }
        }

        return { classname, render };
    }, [type, active]);

    return (
        <div className={`steps__item ${renderInfo.classname || ""}`.trim()} style={styles.item || {}}>
            <div className="steps__item__type">
                {renderInfo.render}
                {step && step.name && <span className="label">{step.name}</span>}
                {type === STEP_TYPE.NORMAL && <span className="step-num">{processing ? <Loader relative /> : step.stepNumber}</span>}
            </div>
            {hasNext && (
                <div className="steps__item__line">
                    <div className="loading" style={{ width: typeof loading === "number" ? loading + "%" : "0%" }}></div>
                    <div className="placeholder"></div>
                </div>
            )}
        </div>
    );
}

function Steps({ items = [], currentIndex = 0, styles = {}, className, noFirstLine, isLoading, error, warning }) {
    const [trackItems, setTrackItems] = useState(
        (items || []).map((item) => ({
            name: item,
            error: null,
            warning: null
        }))
    );

    const total = items.length;

    useEffect(() => {
        setTrackItems((prev) => {
            return prev.map((item, idx) => {
                let temp = {
                    name: item.name,
                    error: null,
                    warning: null,
                    isGoingNextOrBackPercent: 0
                };
                temp.error = error?.[idx] || null;
                temp.warning = warning?.[idx] || null;
                temp.isGoingNextOrBackPercent = currentIndex > idx ? 100 : 0;
                return temp;
            });
        });
    }, [error, warning, isLoading, currentIndex]);

    if (!total) {
        return <></>;
    }

    return (
        <div className={`steps ${className || ""}`.trim()} style={styles?.parent}>
            {!noFirstLine && (
                <div className="steps__item" style={{ width: "25%", ...styles?.item }}>
                    <div className="steps__item__line">
                        <div className="loading" style={{ width: "100%", borderTop: "6px solid #0052CC" }}></div>
                        <div className="placeholder"></div>
                    </div>
                </div>
            )}
            {trackItems.map((step, idx) => (
                <StepLine
                    key={idx}
                    step={{
                        name: step.name,
                        stepIdx: idx,
                        stepNumber: idx + 1
                    }}
                    type={
                        step.error
                            ? STEP_TYPE.ERROR
                            : step.warning
                              ? STEP_TYPE.WARNING
                              : total - 1 === idx && currentIndex === idx
                                ? STEP_TYPE.COMPLETED
                                : currentIndex > idx
                                  ? STEP_TYPE.CHECK
                                  : STEP_TYPE.NORMAL
                    }
                    active={currentIndex === idx}
                    hasNext={idx < total - 1}
                    loading={step.isGoingNextOrBackPercent}
                    processing={currentIndex === idx && isLoading}
                    styles={{
                        item: {
                            ...styles?.step,
                            width: total - 1 === idx ? "auto" : 100 / total + "%"
                        }
                    }}
                />
            ))}
        </div>
    );
}

StepLine.propTypes = {
    step: PropTypes.shape({
        stepNumber: PropTypes.number,
        name: PropTypes.string
    }),
    type: PropTypes.oneOf(Object.values(STEP_TYPE)),
    hasNext: PropTypes.bool,
    active: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    styles: PropTypes.shape({
        item: PropTypes.object
    }),
    processing: PropTypes.bool
};

Steps.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    currentIndex: PropTypes.number,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        item: PropTypes.object,
        step: PropTypes.object
    }),
    className: PropTypes.string,
    noFirstLine: PropTypes.bool,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    warning: PropTypes.object
};

StepWizard.propTypes = {
    currentIndex: PropTypes.number,
    onChange: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.element),
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    warning: PropTypes.object,
    fallback: PropTypes.element,
    unSkippableSteps: PropTypes.arrayOf(PropTypes.number),
    combinedNextSkipIndexes: PropTypes.arrayOf(PropTypes.number),
    noBackButtonIndexes: PropTypes.arrayOf(PropTypes.number),
    disabledNext: PropTypes.bool,
    isProcessing: PropTypes.bool,
    completedRetryLabel: PropTypes.any,
    nextLabel: PropTypes.any,
    nextTooltip: PropTypes.object
};

export default StepWizard;
