import React, { useEffect, useState } from "react";
import { convertToObject, createToast, TOAST_TYPE } from "../../../common/utilities/helper";
import { ReactComponent as WarningSVG } from "../../../assets/images/warning-icon.svg";
import { useCheckDuplicateMutation, useProcessEmployeesMutation } from "../employees/api";
import { reset } from "../employees/slice";
import BulkIcon from "./BulkIcon";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";

export const useCheckDuplicates = ({ processToken, value = {}, onError, onWarning, onChange, onStart, onFinish }) => {
    const [isProcessing, setProcessing] = useState(true);
    const [checkDuplicate, { isLoading }] = useCheckDuplicateMutation();

    const [object, setObject] = useState({
        employees: value?.employees || [],
        summary: {
            toBeInserted: value?.summary?.toBeInserted || 0,
            toBeUpdated: value?.summary?.toBeUpdated || 0
        }
    });

    const noDuplicates = object.employees && !object.employees.length;
    const hasInsert = !!object.summary?.toBeInserted;
    const isAllKeepOriginal = object.employees?.every((emp) => !!emp.keepOriginal) || false;

    const updateObject = (newObj = {}) => {
        const newobj = {
            ...object,
            ...newObj
        };
        setObject(newobj);
        onChange(newObj);
    };

    const checkDuplicates = async () => {
        try {
            if (object.employees.length) {
                return Promise.resolve({
                    dups: object.employees,
                    summary: object.summary
                });
            }
            const result = await checkDuplicate({
                extraPath: processToken
            });
            if (result.error || !processToken) {
                throw new Error(result.error?.data?.message || "Something went wrong, please try again later.");
            } else {
                const employees = result.data?.data?.duplicates || [];
                const newRecords = result.data?.data?.newRecords || [];
                const noChangesRecords = result.data?.data?.noChangesRecords || [];
                const summary = result.data?.data?.summary || {};

                onError(null);
                onWarning(null);
                updateObject({
                    newRecords,
                    employees,
                    noChangesRecords,
                    summary: {
                        toBeInserted: summary.toBeInserted || 0,
                        toBeUpdated: summary.toBeUpdated || 0,
                        totalNoChanges: summary.totalNoChanges || 0
                    }
                });
                return { dups: employees, newRecords, noChangesRecords, summary };
            }
        } catch (error) {
            onError?.(
                <BulkIcon
                    img={<WarningSVG color="#F33636" style={{ width: "7rem" }} />}
                    text={
                        <span style={{ maxWidth: "17rem", textAlign: "center", display: "inline-block" }}>
                            Unable to process request. Please try again later or contact support.
                        </span>
                    }
                />
            );
            createToast(error.message || "Something went wrong, please try again later.", TOAST_TYPE.ERROR);
            return { error };
        }
    };

    useEffect(() => {
        onStart();
        setProcessing(true);
        checkDuplicates().finally(() => {
            setProcessing(false);
            onFinish();
        });
    }, []);

    return [object, { noDuplicates, hasInsert, isAllKeepOriginal, isLoading: isLoading || isProcessing, updateObject }];
};

export const useBulkProcessEmployees = () => {
    const [processEmployees, { isLoading }] = useProcessEmployeesMutation();

    const dispatch = useAppDispatch();

    const bulkProcessEmployees = async (processToken, object) => {
        const result = await processEmployees({
            body: {
                changes: object.employees
                    .filter((emp) => !emp.keepOriginal)
                    .map((emp) => {
                        const selected = convertToObject(emp.selected);
                        return { original: emp.original, selected };
                    })
            },
            extraPath: processToken
        });
        if (result.error) {
            throw new Error(result.error.data?.message || "Something went wrong, please try again later.");
        } else {
            dispatch(reset());
            return result.data?.data;
        }
    };

    return [bulkProcessEmployees, isLoading];
};
