import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import FileUploader from "../../../common/components/extra/fileUploader/FileUploader";
import { FILE_MIME_TYPES } from "../../../common/utilities/const";
import {
    bytesToReadable,
    createFullName,
    getPreviousValue,
    megabytesToBytes,
    sanitizeWords,
    trimToLowerJoin
} from "../../../common/utilities/helper";
import { ERROR_TYPE, FILE_STATES } from "../../../common/components/extra/fileUploader/const";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCountry, selectToken } from "../../common/slice";
import {
    UPLOAD_ENDPOINT,
    FILE_SIZE_LIMIT,
    POSSIBLE_FILES,
    EXCLUDED_KEY_FROM_POSSIBLE_FOLDER_NAME_VALUES,
    UNIQUE_VALUES_USED_FOR_UPLOADING_FILES,
    SUPPORTED_DOCUMENT_FILE_NAME_TO_UPLOAD
} from "./const";

function Component(
    { value, employees, onSuccess, onRemoveUploads, onUploadStart, onUploadAdd, onStart, onFinish, onError, onWarning, error, warning, processToken },
    uploadRef
) {
    const country = useAppSelector(selectCountry);
    const token = useAppSelector(selectToken);

    const isInUAE = country === "ae";

    const uploadConfig = {
        config: { method: "POST", params: {}, autoUpload: false }, // we need to upload the files on the temp folder first
        destination: {
            url: UPLOAD_ENDPOINT + `/${processToken}`,
            headers: { authorization: `Bearer ${token}` }
        }
    };

    const getPossibleFolderNameValues = (employee) =>
        Object.keys(employee)
            .filter((key) => !!trimToLowerJoin(employee[key]) && !EXCLUDED_KEY_FROM_POSSIBLE_FOLDER_NAME_VALUES.includes(key))
            .map((key) => `/${trimToLowerJoin(employee[key])}/`);

    const handleUploadAdd = (batch) => {
        let warningMsg = "";
        let error = "";
        let folderErrors = {};
        let notfoundEmployees = [];

        let files = batch.items.map((it) => ({
            ...it,
            file: it.file,
            hdcFullPath: trimToLowerJoin(it.file.hdcFullPath),
            name: trimToLowerJoin(it.file.name.split("/").pop()),
            id: trimToLowerJoin(getPreviousValue(it.file.name))
        }));

        // filter supported filenames
        files = files.filter((file) =>
            SUPPORTED_DOCUMENT_FILE_NAME_TO_UPLOAD.some((supportedFileName) => file.name && file.name.split(".").shift() === supportedFileName)
        );

        // must have valid folder structure
        files.forEach((item) => {
            const path = item.hdcFullPath;
            const splitpath = path ? path.split("/").map(Boolean) : [];
            if (splitpath.length < 3) {
                handleError({
                    type: ERROR_TYPE.CUSTOM,
                    code: "custom",
                    message: `Invalid folder structure:[${path}]. Ex. {parentFolder}/{ID}/{files.(pdf/jpg/jpeg/png)}`
                });
                return false;
            }
        });
        // if no employees that means something weird happen and this shouldnt go to this step if employees is null so we throw error instead incase this happens
        if (!employees) {
            error = "Something went wrong. Please go back to the previous step.";
        }

        employees.forEach((employee) => {
            const possibleValues = getPossibleFolderNameValues(employee);

            // Filter files related to the current employee id (Passport ID/Company ID/Visa ID/Labor Card ID/Residence ID)
            const empFiles = files.filter((it) => {
                return possibleValues.some((val) => trimToLowerJoin(it.hdcFullPath).includes(val));
            });

            if (empFiles.length) {
                POSSIBLE_FILES.forEach((f) => {
                    // Check if any of the employee files match the required file
                    const matchingFiles = empFiles.filter((emp) => emp.hdcFullPath.toLowerCase().includes(f.key.toLowerCase()));

                    matchingFiles.forEach((matchingFile) => {
                        if (!f.accepts.includes(matchingFile.file.type)) {
                            if (!folderErrors?.[matchingFile.hdcFullPath]) {
                                folderErrors = { ...folderErrors, [matchingFile.hdcFullPath]: {} };
                            }
                            folderErrors[matchingFile.hdcFullPath].hasInvalidFileTypes = true;
                        }
                        if (matchingFile.file.size > megabytesToBytes(FILE_SIZE_LIMIT)) {
                            if (!folderErrors?.[matchingFile.hdcFullPath]) {
                                folderErrors = { ...folderErrors, [matchingFile.hdcFullPath]: {} };
                            }
                            folderErrors[matchingFile.hdcFullPath].hasInvalidFileSize = true;
                        }
                    });
                });
            } else {
                // Employee not found in the folder structure
                notfoundEmployees.push(employee);
            }
        });

        // throw the error when the not found employees has len.
        if (notfoundEmployees.length) {
            notfoundEmployees.sort((a, b) => a.rowNum - b.rowNum);
            warningMsg = (
                <div className="bu-custom-error">
                    The following employees will not be included in the upload because their folders are not found, if not intended please check if
                    the folder name that was used is supported and matches the uploaded excel file.
                    <ol className="flex column gap-03" style={{ paddingLeft: "0", maxHeiht: "5rem", overflow: "auto" }}>
                        {notfoundEmployees.map((employee, idx) => (
                            <li
                                key={idx}
                                className="flex gap-05 has-list-style small-font semi-bold"
                                style={{ alignItems: "center", paddingLeft: "1rem", listStyle: "disc" }}
                            >
                                <span>
                                    <span>{createFullName(employee.first_name, employee.last_name)}</span>
                                    <ul>
                                        {UNIQUE_VALUES_USED_FOR_UPLOADING_FILES.filter((key) => employee[key]).map((key) => (
                                            <li key={key}>
                                                <span>{sanitizeWords(key.replace("ID", "_ID"), "_").replace(" Id", " ID")}:&nbsp;</span>
                                                <span className="primary-color semi-bold">{employee[key].toUpperCase()}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </span>
                            </li>
                        ))}
                    </ol>
                </div>
            );
        }
        // show the employees that has invalid files without pointing the specific wrong file instead point them to the instructions.
        if (Object.keys(folderErrors).length) {
            error = (
                <div className="bu-custom-error">
                    The following employee folders doesnt follow the required format. Please follow the instructions above.
                    <ol style={{ gap: ".2rem" }}>
                        {Object.entries(folderErrors).map(([key, value], idx) => (
                            <li key={idx} className="flex gap-05" style={{ alignItems: "center" }}>
                                <span>{key}</span>
                                <span>-</span>
                                <span className="flex gap-05">
                                    {value.hasIncompleteFiles && <Tag className="red">Incomplete Files</Tag>}
                                    {value.hasInvalidFileTypes && <Tag className="red">Invalid File Type</Tag>}
                                    {value.hasInvalidFileSize && <Tag className="red">Invalid File Size</Tag>}
                                </span>
                            </li>
                        ))}
                    </ol>
                </div>
            );
        }

        if (error) {
            handleError({
                type: ERROR_TYPE.CUSTOM,
                code: "custom",
                message: error
            });
            return false;
        }

        batch.items = batch.items.filter((b) =>
            employees.some((employee) => {
                const possibleValues = getPossibleFolderNameValues(employee);
                return possibleValues.some((val) => trimToLowerJoin(b.file.hdcFullPath).includes(val));
            })
        );

        if (!batch.items.length) {
            warningMsg = (
                <div className="bu-custom-error">
                    No folders were found for the employees. Please follow the instructions above or skip this step if you dont have any files to
                    upload.
                </div>
            );
        }

        if (warningMsg) {
            handleWarning(warningMsg);
        } else {
            handleWarning(null);
        }

        handleError(null);
        onUploadAdd?.(batch);
        return batch;
    };

    const handleUploadComplete = (err, batch) => {
        const item = (Array.isArray(batch.items) && batch.items.length > 0 && batch.items[0]) || {};
        const response = item?.uploadResponse?.data || {};
        const message = response.message;
        const errorResponse = response.status == 0;
        // throw an error incase if the response is an error
        if (err && errorResponse) {
            const isError = item.state === FILE_STATES.ERROR;
            if (isError) {
                handleError({
                    type: ERROR_TYPE.CUSTOM,
                    code: "custom",
                    message: (
                        <ul>
                            <li
                                key={item.id}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto 1fr",
                                    columnGap: ".5rem"
                                }}
                            >
                                <span style={{ fontWeight: 600 }}>File:</span>
                                <span>{item.file.name}</span>
                                <span style={{ fontWeight: 600 }}>Error:</span>
                                <span>{message || "Upload failed."} </span>
                            </li>
                        </ul>
                    )
                });
            } else {
                onFinish();
            }
            return { reset: true };
        }
        onFinish();
        typeof onSuccess === "function" &&
            onSuccess({
                totalFileSize: bytesToReadable(batch.items.map((it) => it.file.size).reduce((prev, curr) => prev + curr, 0))
            });
    };

    const handleItemStart = (item) => {
        onStart?.();
        // make sure to only add the item folder for the said employee to prevent from sending all files to the server.
        return employees.some((emp) => {
            const possibleValues = getPossibleFolderNameValues(emp);
            return possibleValues.some((val) => trimToLowerJoin(item.file.hdcFullPath).includes(val));
        });
    };

    const handleError = (error) => {
        onError?.(error);
        onFinish();
    };

    const handleWarning = (warning) => {
        onWarning?.(warning);
    };

    const handleRemove = () => {
        typeof onRemoveUploads === "function" && onRemoveUploads();
    };

    return (
        <div className="tk-bulk-upload__reqfile" style={{ display: "flex" }}>
            <FileUploader
                ref={uploadRef}
                value={value}
                accepts={[FILE_MIME_TYPES.FOLDER]}
                onUploadComplete={handleUploadComplete}
                onAdd={handleUploadAdd}
                onItemStart={handleItemStart}
                onProcessing={onUploadStart}
                error={error}
                warning={warning}
                onError={handleError}
                // we dont need the internal file type and size check because we already created our custom error handler for it.
                noFileTypeCheck={true}
                noFileSizeCheck={true}
                style={{ maxHeight: "calc(100vh - 23rem)" }}
                instruction={
                    <div className="instructions">
                        <ol>
                            <li>
                                Must follow the required folder structure: “employees/<span className="warning-color semi-bold">{`{ID}`}</span>
                                /photo.png”
                            </li>
                            <li>
                                The value of the ID can be either the{" "}
                                <span className="warning-color semi-bold">
                                    Passport ID/Company ID/Residence ID/Visa ID{isInUAE && `/Labor Card ID`}
                                </span>{" "}
                                of the Employee.
                            </li>
                            <li>Each File should not exceed a {FILE_SIZE_LIMIT}MB file size.</li>
                            <li>
                                The following are the accepted files that can be uploaded per employee, and must follow the correct filename:
                                <ul>
                                    <li className="warning-color semi-bold">photo (.png,.jpg.jpeg)</li>
                                    <li className="warning-color semi-bold">residence (.png, .jpg, .jpeg, .pdf)</li>
                                    <li className="warning-color semi-bold">passport (.png, .jpg, .jpeg, .pdf)</li>
                                    <li className="warning-color semi-bold">contract (.png, .jpg, .jpeg, .pdf)</li>
                                    <li className="warning-color semi-bold">visa (.png, .jpg, .jpeg, .pdf)</li>
                                    {isInUAE && <li className="warning-color semi-bold">labor-card (.png, .jpg, .jpeg, .pdf)</li>}
                                </ul>
                            </li>
                        </ol>
                    </div>
                }
                onRemove={handleRemove}
                {...uploadConfig}
            />
        </div>
    );
}

const BulkUploadFiles = forwardRef(Component);

Component.propTypes = {
    employees: PropTypes.arrayOf(
        PropTypes.shape({
            companyID: PropTypes.string,
            residenceID: PropTypes.string,
            passportID: PropTypes.string,
            visaID: PropTypes.string,
            labor_card_number: PropTypes.string
        })
    ),
    onSuccess: PropTypes.func,
    onRemoveUploads: PropTypes.func,
    onStart: PropTypes.func,
    onFinish: PropTypes.func,
    onError: PropTypes.func,
    onWarning: PropTypes.func,
    error: PropTypes.object,
    warning: PropTypes.object,
    onUploadStart: PropTypes.func,
    onUploadAdd: PropTypes.func,
    value: PropTypes.any,
    processToken: PropTypes.string
};

export default BulkUploadFiles;
