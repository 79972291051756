import React, { useState } from "react";
import PropTypes from "prop-types";
import Loader from "../../../common/components/extra/Loader";
import { ReactComponent as ProceedCheckedSVG } from "../../../assets/images/proceed-checked.svg";
import { ReactComponent as SimpleSuccessSVG } from "../../../assets/images/simple-success-doc-icon.svg";
import ManageDuplicates from "./ManageDuplicates";
import BulkIcon from "./BulkIcon";
import { useCheckDuplicates } from "./hooks";

function BulkUploadCheckDuplicates({ value, onError, onWarning, error, onChange, onStart, onFinish, processToken }) {
    // we only need to check during on mount so we used it on the state instead with no setter
    const [usingCachedValue] = useState(!!value?.employees);
    const [object, { isLoading, updateObject }] = useCheckDuplicates({
        value,
        onError,
        onWarning,
        onChange,
        onStart,
        onFinish,
        processToken
    });

    const noDuplicates = object.employees && !object.employees.length;
    const hasInsert = !!object.summary?.toBeInserted;
    const hasError = !!error;
    const isAllKeepOriginal = object.employees?.every((emp) => !!emp.keepOriginal) || false;

    const setEmployees = (newEmployees = []) => {
        updateObject({ ...object, employees: newEmployees });
    };

    const render = () => {
        const commonStyle = { maxWidth: "30rem", textAlign: "center" };
        if (isLoading) {
            return <BulkIcon img={<Loader relative />} text="Checking for duplicates..." />;
        } else if (hasError) {
            return error;
        } else if (noDuplicates && !hasInsert) {
            return (
                <BulkIcon
                    img={<ProceedCheckedSVG color="#10CC00" className="lg" />}
                    text={
                        <div className="flex gap-05 center" style={commonStyle}>
                            <span>No new records or changes were found. Proceed to next step if you want to upload files.</span>
                        </div>
                    }
                />
            );
        } else if (noDuplicates && hasInsert) {
            return (
                <BulkIcon
                    img={<SimpleSuccessSVG color="#10CC00" className="lg" />}
                    text={
                        <div className="flex center" style={commonStyle}>
                            <span>
                                No changes were found and <strong>{object.summary.toBeInserted}</strong> record(s) will be inserted. Proceed to next
                                step if you want to upload files.
                            </span>
                        </div>
                    }
                />
            );
        } else {
            return (
                <div className="tk-bulk-upload__check-duplicates__content">
                    <ManageDuplicates
                        employees={object.employees || []}
                        setEmployees={setEmployees}
                        isAllKeepOriginal={isAllKeepOriginal}
                        toBeInserted={object.summary?.toBeInserted}
                        usingCachedValue={usingCachedValue}
                    />
                </div>
            );
        }
    };

    return (
        <div
            className="tk-bulk-upload__check-duplicates"
            style={{
                pointerEvents: isLoading ? "none" : "auto",
                display: "flex"
            }}
        >
            {render()}
        </div>
    );
}

BulkUploadCheckDuplicates.propTypes = {
    onError: PropTypes.func,
    onWarning: PropTypes.func,
    error: PropTypes.any,
    onChange: PropTypes.func,
    onStart: PropTypes.func,
    onFinish: PropTypes.func,
    value: PropTypes.object,
    processToken: PropTypes.string
};

export default BulkUploadCheckDuplicates;
