import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { createGroup, toProperTimezoneConversion } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserCountrySettings, selectUserSetting } from "../../common/slice";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { EMPLOYEE_FILES, STANDARD_DATE_FORMAT } from "../../../common/utilities/const";
import InputEmployeeFile from "./InputEmployeeFile";
import { PERSONAL_FIELDS } from "./const";

function UpdateFilesInputs({ base, form, current, isGettingEmployee, onChange }) {
    const setting = useAppSelector(selectUserSetting);
    const countrySetting = useAppSelector(selectUserCountrySettings);

    const fileIdConfig = countrySetting.file_id_confg;
    const supportedFileIds = fileIdConfig.supported || [];

    const timezone = setting.timezone;
    const validity = current.validity;
    const contractValidity = validity?.contract || {};
    const passportValidity = validity?.passportID || {};
    const residenceValidity = validity?.residenceID || {};
    const visaValidity = validity?.visaID || {};
    const laborCardValidity = validity?.labor_card_number || {};

    const createInputDateExpiry = (obj = {}, label, name, value, required = false, subtext) => {
        const date = obj.date && toProperTimezoneConversion(obj.date, timezone).format(STANDARD_DATE_FORMAT);
        const newValue = value && toProperTimezoneConversion(value, timezone).format(STANDARD_DATE_FORMAT);
        const isSame = date && newValue && isEqual(date, newValue);
        return (
            <Input
                type={INPUT_TYPE.DATE}
                label={label}
                afterLabel={
                    isSame &&
                    (obj.isExpired || obj.isWarning) && (
                        <WarningColorSwitcher
                            style={{ width: "1rem" }}
                            tooltip={{
                                message: obj.isWarning ? "Is Expiring" : "Is Expired",
                                className: "flex align-center",
                                style: { marginBottom: "3px" }
                            }}
                            danger={obj.isExpired}
                            warning={obj.isWarning}
                        />
                    )
                }
                name={name}
                onChange={(value) => onChange({ target: { name, value: value || null } })}
                selected={value && new Date(value)}
                isLoading={isGettingEmployee}
                timezone={timezone}
                required={required}
                subtext={subtext}
                noPast
                useSubTextStyle
            />
        );
    };

    return (
        <>
            {createGroup({
                base,
                title: "Documents",
                body: (
                    <>
                        {createInputDateExpiry(
                            contractValidity,
                            "Contract Expiry Date",
                            "EmployeeContract.end_date",
                            form?.EmployeeContract?.end_date,
                            true,
                            { message: <span>Same as Contract End Date</span> }
                        )}

                        <InputEmployeeFile
                            keyName={EMPLOYEE_FILES.CONTRACT.key}
                            id={current?.id}
                            style={{ marginTop: ".5rem" }}
                            value={form.uploads[EMPLOYEE_FILES.CONTRACT.key]}
                            label="Attach Contract Copy"
                            name="uploads.contract"
                            isLoading={isGettingEmployee}
                            onChange={(_, file) => onChange({ target: { name: "uploads.contract", value: file } })}
                        />
                        {supportedFileIds.includes(PERSONAL_FIELDS.PASSPORT_ID) && (
                            <>
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="Passport No."
                                    name={PERSONAL_FIELDS.PASSPORT_ID}
                                    onChange={onChange}
                                    value={form[PERSONAL_FIELDS.PASSPORT_ID] || ""}
                                    minLength={fileIdConfig[PERSONAL_FIELDS.PASSPORT_ID].min}
                                    maxLength={fileIdConfig[PERSONAL_FIELDS.PASSPORT_ID].max}
                                    isLoading={isGettingEmployee}
                                    required
                                />

                                {createInputDateExpiry(
                                    passportValidity,
                                    "Passport ID Exp. Date",
                                    "passportID_expiration",
                                    form?.passportID_expiration || "",
                                    true
                                )}

                                <InputEmployeeFile
                                    keyName={EMPLOYEE_FILES.PASSPORT.key}
                                    id={current?.id}
                                    style={{ marginTop: ".5rem" }}
                                    value={form.uploads[EMPLOYEE_FILES.PASSPORT.key]}
                                    label="Attach Passport Copy"
                                    name="uploads.passport"
                                    isLoading={isGettingEmployee}
                                    onChange={(_, file) => onChange({ target: { name: "uploads.passport", value: file } })}
                                />
                            </>
                        )}

                        {supportedFileIds.includes(PERSONAL_FIELDS.RESIDENCE_ID) && (
                            <>
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="Residence ID No."
                                    name={PERSONAL_FIELDS.RESIDENCE_ID}
                                    onChange={onChange}
                                    value={form[PERSONAL_FIELDS.RESIDENCE_ID] || ""}
                                    minLength={fileIdConfig[PERSONAL_FIELDS.RESIDENCE_ID].min}
                                    maxLength={fileIdConfig[PERSONAL_FIELDS.RESIDENCE_ID].max}
                                    isLoading={isGettingEmployee}
                                    required={!!(form?.residenceID_expiration || form.uploads[EMPLOYEE_FILES.RESIDENCE.key])}
                                />

                                {createInputDateExpiry(
                                    residenceValidity,
                                    "Residence ID Exp. Date",
                                    "residenceID_expiration",
                                    form?.residenceID_expiration || "",
                                    !!(form?.residenceID || form.uploads[EMPLOYEE_FILES.RESIDENCE.key])
                                )}

                                <InputEmployeeFile
                                    keyName={EMPLOYEE_FILES.RESIDENCE.key}
                                    id={current?.id}
                                    label="Attach ID Copy"
                                    name="uploads.residence"
                                    style={{ marginTop: ".5rem" }}
                                    value={form.uploads[EMPLOYEE_FILES.RESIDENCE.key]}
                                    isLoading={isGettingEmployee}
                                    onChange={(_, file) => onChange({ target: { name: "uploads.residence", value: file } })}
                                    alwaysEnableRemove
                                />
                            </>
                        )}

                        {supportedFileIds.includes(PERSONAL_FIELDS.LABOR_CARD_NUMBER) && (
                            <>
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="Labor Card No."
                                    name={PERSONAL_FIELDS.LABOR_CARD_NUMBER}
                                    onChange={onChange}
                                    value={form[PERSONAL_FIELDS.LABOR_CARD_NUMBER] || ""}
                                    minLength={fileIdConfig[PERSONAL_FIELDS.LABOR_CARD_NUMBER].min}
                                    maxLength={fileIdConfig[PERSONAL_FIELDS.LABOR_CARD_NUMBER].max}
                                    isLoading={isGettingEmployee}
                                    required={!!(form?.labor_card_expiration || form.uploads[EMPLOYEE_FILES.LABOR_CARD.key])}
                                />

                                {createInputDateExpiry(
                                    laborCardValidity,
                                    "Labor Card Exp. Date",
                                    "labor_card_expiration",
                                    form?.labor_card_expiration || "",
                                    !!(form?.labor_card_number || form.uploads[EMPLOYEE_FILES.LABOR_CARD.key])
                                )}

                                <InputEmployeeFile
                                    keyName={EMPLOYEE_FILES.LABOR_CARD.key}
                                    id={current?.id}
                                    style={{ marginTop: ".5rem" }}
                                    value={form.uploads[EMPLOYEE_FILES.LABOR_CARD.key]}
                                    label="Attach Labor Card Copy"
                                    name="uploads.labor-card"
                                    isLoading={isGettingEmployee}
                                    onChange={(_, file) => onChange({ target: { name: "uploads.labor-card", value: file } })}
                                    alwaysEnableRemove
                                />
                            </>
                        )}

                        {supportedFileIds.includes(PERSONAL_FIELDS.VISA_ID) && (
                            <>
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="Visa No."
                                    name={PERSONAL_FIELDS.VISA_ID}
                                    onChange={onChange}
                                    value={form[PERSONAL_FIELDS.VISA_ID] || ""}
                                    minLength={fileIdConfig[PERSONAL_FIELDS.VISA_ID].min}
                                    maxLength={fileIdConfig[PERSONAL_FIELDS.VISA_ID].max}
                                    isLoading={isGettingEmployee}
                                    required={!!(form?.visaID_expiration || form.uploads[EMPLOYEE_FILES.VISA.key])}
                                />

                                {createInputDateExpiry(
                                    visaValidity,
                                    "Visa ID Exp. Date",
                                    "visaID_expiration",
                                    form?.visaID_expiration || "",
                                    !!(form?.visaID || form.uploads[EMPLOYEE_FILES.VISA.key])
                                )}

                                <InputEmployeeFile
                                    keyName={EMPLOYEE_FILES.VISA.key}
                                    id={current?.id}
                                    style={{ marginTop: ".5rem" }}
                                    value={form.uploads[EMPLOYEE_FILES.VISA.key]}
                                    label="Attach Visa Copy"
                                    name="uploads.visa"
                                    isLoading={isGettingEmployee}
                                    onChange={(_, file) => onChange({ target: { name: "uploads.visa", value: file } })}
                                    alwaysEnableRemove
                                />
                            </>
                        )}
                    </>
                )
            })}
        </>
    );
}

export default UpdateFilesInputs;

UpdateFilesInputs.propTypes = {
    base: PropTypes.string,
    form: PropTypes.object,
    current: PropTypes.object,
    isGettingEmployee: PropTypes.bool,
    onChange: PropTypes.func
};
