import PropTypes from "prop-types";
import React from "react";

const BulkIcon = ({ img, text }) => {
    return (
        <div className="icon">
            {img}
            <div>{text}</div>
        </div>
    );
};

export default BulkIcon;

BulkIcon.propTypes = {
    img: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
};
