import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { DATE_STATUS, EMPLOYEE_INSURANCE_PLAN, EMPLOYEE_INSURANCE_STATUS, EMPLOYEE_INSURANCE_TYPE } from "./const";
import { sanitizeWords, toProperTimezoneConversion } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import { READABLE_DATE } from "../../../common/utilities/const";
import Empty from "../../../common/components/extra/Empty";
import ViewInsuranceModal from "./ViewInsuranceModal";
import { getInsuranceDateStatus } from "./helper";
import Button from "../../../common/components/extra/Button";

const InsuranceMiniCard = ({ data = {}, isLoading }) => {
    const [open, setOpen] = useState(false);

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const toInsuranceFormatDate = (newDate) => {
        if (!newDate) {
            return "";
        }
        return toProperTimezoneConversion(newDate, timezone).format(READABLE_DATE);
    };

    const dateStatus = useMemo(() => {
        return getInsuranceDateStatus(data, timezone);
    }, [data?.coverage_expiration]);

    const isExpiringInsurance = DATE_STATUS.EXPIRING === dateStatus.value;
    const isExpiredInsurance = DATE_STATUS.EXPIRED === dateStatus.value;
    const isNormalStatus = dateStatus.value === DATE_STATUS.NORMAL;

    const content = useMemo(() => {
        if (data?.coverage_expiration) {
            return (
                <>
                    <div className="insurance-mini-card__left">
                        <div className="insurance-mini-card__title">{sanitizeWords(data.type, "_")} Insurance</div>
                        <div className="insurance-mini-card__subtext">
                            <span className="valid-until small-font semi-bold flex gap-05 align-center">
                                <span className="fade">Valid Until: </span>
                                <div className="flex gap-05 align-center">
                                    <span className={dateStatus.className}>{toInsuranceFormatDate(data.coverage_expiration)}</span>
                                    {!isNormalStatus && (
                                        <WarningColorSwitcher
                                            warning={isExpiringInsurance}
                                            danger={isExpiredInsurance}
                                            tooltip={{
                                                message: isExpiringInsurance ? `Expiring in ${dateStatus.remainingDays} Day(s)` : "Already Expired."
                                            }}
                                            style={{ width: "1.2rem" }}
                                        />
                                    )}
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="insurance-mini-card__divider"></div>
                    <div className="insurance-mini-card__right">
                        <div className="insurance-mini-card__title status fade">{sanitizeWords(data.status, "_").toUpperCase()}</div>
                    </div>
                    <div className="insurance-mini-card__action">
                        <Button
                            options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                            onClick={() => setOpen(true)}
                            transparent
                            small
                        >
                            View
                        </Button>
                    </div>
                </>
            );
        }
        return (
            <Empty
                style={{ margin: "auto", flexDirection: "row" }}
                loaderStyle={{ width: "2rem" }}
                message="No Insurance Available"
                isLoading={isLoading}
                noicon
            />
        );
    }, [data]);

    return (
        <div className="insurance-mini-card">
            <div className="insurance-mini-card__inner">{content}</div>
            {open && <ViewInsuranceModal open={open} data={data} onClose={() => setOpen(false)} />}
        </div>
    );
};

export default InsuranceMiniCard;

InsuranceMiniCard.propTypes = {
    data: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_TYPE)),
        status: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_STATUS)),
        plan: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_PLAN)),
        card_number: PropTypes.string,
        company_name: PropTypes.string,
        coverage_start: PropTypes.string,
        coverage_expiration: PropTypes.string
    }),
    isLoading: PropTypes.bool
};
