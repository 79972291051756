import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { EMPLOYEE_INSURANCE_PLAN, EMPLOYEE_INSURANCE_STATUS, EMPLOYEE_INSURANCE_TYPE, INSURANCE_FIELDS } from "./const";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserCountrySettings } from "../../common/slice";

const InsuranceInputs = ({ index, onChange, form, oldInsurances }) => {
    const countrySetting = useAppSelector(selectUserCountrySettings);

    // dont allow same status with the same type // this happen if the user select a status first before type
    const NOT_ALLOWED_INSURANCE_TYPE_BY_STATUS = useMemo(() => {
        return [
            ...new Set(
                oldInsurances
                    .filter((insurance, idx) => index !== idx && insurance[INSURANCE_FIELDS.STATUS] === form[INSURANCE_FIELDS.STATUS])
                    .map((insurance) => insurance[INSURANCE_FIELDS.TYPE])
            )
        ];
    }, [oldInsurances]);

    // dont allow same type with the same status // this happen if the user select a type first before status
    const NOT_ALLOWED_INSURANCE_STATUS_BY_TYPE = useMemo(() => {
        return [
            ...new Set(
                oldInsurances
                    .filter((insurance, idx) => index !== idx && insurance[INSURANCE_FIELDS.TYPE] === form[INSURANCE_FIELDS.TYPE])
                    .map((insurance) => insurance[INSURANCE_FIELDS.STATUS])
            )
        ];
    }, [oldInsurances]);

    const SUPPORTED_INSURANCE_TYPES = (countrySetting?.insurances || [])
        .filter((insurance) => !NOT_ALLOWED_INSURANCE_TYPE_BY_STATUS.includes(insurance))
        .reduce(
            (prev, curr) => ({
                ...prev,
                [curr]: curr
            }),
            {}
        );

    const SUPPORTED_INSURANCE_STATUS = Object.keys(EMPLOYEE_INSURANCE_STATUS)
        .filter((insuranceStatus) => !NOT_ALLOWED_INSURANCE_STATUS_BY_TYPE.includes(insuranceStatus))
        .reduce(
            (prev, curr) => ({
                ...prev,
                [curr]: curr
            }),
            {}
        );

    return (
        <>
            <Input
                type={INPUT_TYPE.TEXT}
                label="Company Name"
                name={INSURANCE_FIELDS.COMPANY_NAME}
                onChange={onChange}
                value={form[INSURANCE_FIELDS.COMPANY_NAME]}
                minLength={3}
                maxLength={30}
            />
            <Input
                type={INPUT_TYPE.TEXT}
                label="Card Number"
                name={INSURANCE_FIELDS.CARD_NUMBER}
                onChange={onChange}
                value={form[INSURANCE_FIELDS.CARD_NUMBER]}
                minLength={3}
                maxLength={30}
            />
            <SelectConstant
                base={SUPPORTED_INSURANCE_TYPES}
                label="Insurance Type"
                name={INSURANCE_FIELDS.TYPE}
                value={form[INSURANCE_FIELDS.TYPE]}
                onChange={(val) =>
                    onChange({
                        target: { name: INSURANCE_FIELDS.TYPE, value: val.value }
                    })
                }
                isOutlined
                disabledOutline
                required
            />
            <SelectConstant
                base={SUPPORTED_INSURANCE_STATUS}
                label="Status"
                name={INSURANCE_FIELDS.STATUS}
                onChange={(val) =>
                    onChange({
                        target: { name: INSURANCE_FIELDS.STATUS, value: val.value }
                    })
                }
                value={form[INSURANCE_FIELDS.STATUS]}
                subtext={{ message: "Personal: The employee pays for the insurance; Company: The company covers the insurance." }}
                useSubTextStyle
                isOutlined
                disabledOutline
                required
            />
            {form?.[INSURANCE_FIELDS.TYPE] !== EMPLOYEE_INSURANCE_TYPE.IOLE && (
                <SelectConstant
                    base={EMPLOYEE_INSURANCE_PLAN}
                    label="Plan"
                    name={INSURANCE_FIELDS.PLAN}
                    onChange={(val) =>
                        onChange({
                            target: { name: INSURANCE_FIELDS.PLAN, value: val.value }
                        })
                    }
                    value={form[INSURANCE_FIELDS.PLAN]}
                    isDisabled={!form?.[INSURANCE_FIELDS.TYPE]}
                    required
                    isOutlined
                    disabledOutline
                />
            )}
            <Input
                type={INPUT_TYPE.DATE}
                label="Start Date"
                name={INSURANCE_FIELDS.COVERAGE_START}
                onChange={(value) =>
                    onChange({
                        target: { name: INSURANCE_FIELDS.COVERAGE_START, value: value || null }
                    })
                }
                selected={(form[INSURANCE_FIELDS.COVERAGE_START] && new Date(form[INSURANCE_FIELDS.COVERAGE_START])) || ""}
                maxDate={(form[INSURANCE_FIELDS.COVERAGE_EXPIRATION] && new Date(form[INSURANCE_FIELDS.COVERAGE_EXPIRATION])) || ""}
                required
            />
            <Input
                type={INPUT_TYPE.DATE}
                label="Valid Until"
                name={INSURANCE_FIELDS.COVERAGE_EXPIRATION}
                onChange={(value) =>
                    onChange({
                        target: {
                            name: INSURANCE_FIELDS.COVERAGE_EXPIRATION,
                            value: value || null
                        }
                    })
                }
                selected={(form[INSURANCE_FIELDS.COVERAGE_EXPIRATION] && new Date(form[INSURANCE_FIELDS.COVERAGE_EXPIRATION])) || ""}
                minDate={(form[INSURANCE_FIELDS.COVERAGE_START] && new Date(form[INSURANCE_FIELDS.COVERAGE_START])) || ""}
                required
            />
        </>
    );
};

export default InsuranceInputs;

InsuranceInputs.propTypes = {
    index: PropTypes.number,
    onChange: PropTypes.func,
    form: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_TYPE).concat("")),
        status: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_STATUS).concat("")),
        plan: PropTypes.oneOf(Object.values(EMPLOYEE_INSURANCE_PLAN).concat("")),
        card_number: PropTypes.string,
        company_name: PropTypes.string,
        coverage_start: PropTypes.string,
        coverage_expiration: PropTypes.string
    }),
    oldInsurances: PropTypes.array
};
