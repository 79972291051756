import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EmptySVG } from "../../../assets/images/empty-data.svg";
import Loader from "./Loader";

function Empty({ icon, message, noicon, className, style, isLoading, loadingMessage, iconStyle, loaderStyle, messageStyle }) {
    let component = icon || <EmptySVG className="primary-color responsive-img" style={{ ...(iconStyle || {}), overflow: "unset" }} />;
    let msg = message || "No Data Available";

    if (isLoading) {
        component = <Loader style={loaderStyle} relative />;
        msg = loadingMessage || "Please wait, fetching data...";
    }

    return (
        <div className={`tk-empty ${className || ""}`.trim()} style={style || {}}>
            {(!noicon || isLoading) && component}
            <span className="fade semi-bold" style={{ whiteSpace: "nowrap", ...(messageStyle || {}) }}>
                {msg}
            </span>
        </div>
    );
}

export default Empty;

Empty.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    noicon: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    loadingMessage: PropTypes.string,
    style: PropTypes.object,
    iconStyle: PropTypes.object,
    loaderStyle: PropTypes.object,
    messageStyle: PropTypes.object
};
