import React, { useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import TableList, { CHECKBOX_TYPE } from "../../../common/components/extra/table/TableListSimple";
import Tag from "../../../common/components/extra/Tag";
import { SORT_ORDER } from "../../../common/utilities/const";
import Button from "../../../common/components/extra/Button";
import { createFullName, flattenObject, performNativeSort } from "../../../common/utilities/helper";
import SelectChangesModal from "./SelectChangesModal";
import { STATUS } from "../employees/const";
import { getHasNoValueToSelect, getStatusFromDuplicates } from "./helper";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import Tooltip from "../../../common/components/extra/Tooltip";

function ManageDuplicates({ employees = [], setEmployees, isAllKeepOriginal, toBeInserted, usingCachedValue }) {
    const [keepOriginalEmployees, setKeepOriginalEmployees] = useState(
        employees.filter((employee) => employee.keepOriginal).map((employee) => employee.id)
    );
    const [selected, setSelected] = useState(null);
    const [sort, setSort] = useState({ sortBy: "id", order: SORT_ORDER.ASC });

    const trackLogs = {
        keep: employees.filter((emp) => emp.keepOriginal).map((emp) => emp.id),
        modified: employees.filter((emp) => emp.modified).map((emp) => emp.id)
    };

    const handleCheckChange = (isChecked, newCheckedIds, type, row) => {
        setEmployees(
            performNativeSort(
                employees.map((emp) => {
                    const employee = cloneDeep(emp);
                    if (newCheckedIds.includes(employee.id)) {
                        employee.modified = false;
                        employee.mixed = false;
                        employee.duplicates = employee.duplicates.map((duplicate) => {
                            duplicate.accepted && delete duplicate.accepted;
                            return duplicate;
                        });
                        delete employee.selected;
                    } else {
                        if (type === CHECKBOX_TYPE.ALL || (type === CHECKBOX_TYPE.INDIVIDUAL && row.id === employee.id)) {
                            // before we accept all dont forget to remove the not allowed keys
                            const firstDuplicate = cloneDeep(emp.duplicates?.[0] || {});
                            const firstDuplicateNotAllowedChangesKeyToUse = firstDuplicate?.notAllowedChangesKeyToUse || [];

                            for (const key in firstDuplicate.employee.changes) {
                                if (Object.prototype.hasOwnProperty.call(firstDuplicate.employee.changes, key)) {
                                    if (firstDuplicateNotAllowedChangesKeyToUse.includes(key)) {
                                        delete firstDuplicate.employee.changes[key];
                                    }
                                }
                            }

                            // use the first duplicate as the default selected
                            employee.duplicates[0].accepted = flattenObject({
                                bank: firstDuplicate.bank.changes,
                                contract: firstDuplicate.contract.changes,
                                employee: firstDuplicate.employee.changes
                            });

                            employee.selected = employee.duplicates[0].accepted;
                            employee.modified = true;
                        }
                    }
                    employee.keepOriginal = !employee.modified;
                    return employee;
                }),
                sort
            )
        );
        setKeepOriginalEmployees(newCheckedIds);
    };

    const handleSelectChanges = (row) => {
        setSelected(row);
    };

    const handleFinish = (newEmployee) => {
        const newEmployees = employees.map((emp) => {
            if (emp.original.id === newEmployee.original.id) return newEmployee;
            return emp;
        });
        setEmployees(newEmployees);
        setKeepOriginalEmployees(newEmployees.filter((employee) => employee.keepOriginal).map((employee) => employee.id));
        setSelected(null);
    };

    const handleSort = ({ sortBy, order }) => {
        const keys = sortBy.split(".");
        const cloned = cloneDeep(employees);
        cloned.sort((a, b) => {
            let valueA = a;
            let valueB = b;
            // Traverse through keys to access nested properties
            for (const key of keys) {
                valueA = valueA[key];
                valueB = valueB[key];
            }
            // Check if both values are numbers
            if (!isNaN(valueA) && !isNaN(valueB)) {
                // Convert values to numbers and compare
                return order === SORT_ORDER.ASC ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
            } else {
                // convert arr to string
                if (sortBy === "duplicates") {
                    Array.isArray(valueA) && (valueA = valueA.map((rd) => rd.rowNum).join(","));
                    Array.isArray(valueB) && (valueB = valueB.map((rd) => rd.rowNum).join(","));
                }
                // If not numbers, compare them as strings
                return order === SORT_ORDER.ASC ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
            }
        });
        setEmployees(cloned);
        setSort({ sortBy, order });
    };

    return (
        <div className="manage-duplicates">
            <details className="manage-duplicates__header" open>
                <summary>Manage Duplicates</summary>
                <p>
                    {`  These are the existing records with differing values found in the spreadsheet. Select 'View Changes' to inspect modifications in
                    the selected row. You can choose to accept the new changes or opt to skip and retain the existing data. Original values are accepted
                    by default.`}
                </p>
            </details>
            <TableList
                uniqueKey="id"
                headers={CreateTableHeaders({ onSelect: handleSelectChanges }).data}
                data={employees}
                onSort={handleSort}
                onCheckChange={handleCheckChange}
                activeSort={sort}
                selected={keepOriginalEmployees}
                checkedByDefault={!usingCachedValue}
                disabledUniqueKeys={employees.filter((employee) => getHasNoValueToSelect(employee.duplicates)).map((employee) => employee.id)}
            />
            {!isAllKeepOriginal && (!!trackLogs.keep.length || !!trackLogs.modified.length) && (
                <details className="manage-duplicates__footer" open>
                    <summary>View Selection Details</summary>
                    <div className="manage-duplicates__footer__content">
                        {!!trackLogs.keep.length && (
                            <div className="md-group">
                                <span>Keeping Original Data:</span>
                                <ul>
                                    {trackLogs.keep.map((value, i) => (
                                        <li key={i}>
                                            <Tag>TABLE ID - {value}</Tag>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {!!trackLogs.modified.length && (
                            <div className="md-group">
                                <span>Will be Modified:</span>
                                <ul>
                                    {trackLogs.modified.map((value, i) => (
                                        <li key={i}>
                                            <Tag className={STATUS.CUSTOM.color}>TABLE ID - {value}</Tag>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </details>
            )}
            {isAllKeepOriginal && !toBeInserted && (
                <div className="manage-duplicates__footer__keep flex gap-05">
                    <span>All existing employee records will be retained. Proceed to next step if you want to upload files.</span>
                </div>
            )}
            {isAllKeepOriginal && !!toBeInserted && (
                <div className="manage-duplicates__footer__keep flex gap-05">
                    <span>
                        All existing employee records will remain unchanged, and {toBeInserted} new record(s) to be inserted. Please proceed to the
                        next step to upload files or you can skip that step and view the final result.
                    </span>
                </div>
            )}
            {!!selected && (
                <SelectChangesModal open={!!selected} data={selected} onChange={(bool) => !bool && setSelected(null)} onFinish={handleFinish} />
            )}
        </div>
    );
}

const CreateTableHeaders = ({ onSelect }) => {
    const headers = {
        ID: {
            key: "id",
            sortKey: "id",
            label: <span style={{ whiteSpace: "nowrap" }}>Table ID</span>,
            width: "10%",
            render: (row) => <span className="bold">{row.id}</span>
        },
        ROW: {
            key: "row",
            sortKey: "duplicates",
            label: "CSV Rows",
            width: "10%",
            render: (row) => (
                <span className="bold text-ellipsis" style={{ display: "block", maxWidth: "4rem" }}>
                    {row.duplicates.map((rd) => rd.rowNum).join(", ")}
                </span>
            )
        },
        EMPLOYEE: {
            key: "employee",
            sortKey: "original.generatedID",
            label: "Employee ID / Full Name",
            width: "30%",
            render: (row) => {
                const hasNoValueToSelect = getHasNoValueToSelect(row.duplicates);
                return (
                    <div className="flex align-center gap-05 link-hover">
                        <Tooltip
                            className="flex align-center gap-05 link-hover"
                            message={hasNoValueToSelect ? "No values can be selected. Click to View Record." : "Click to Manage Changes."}
                            onClick={() => onSelect?.(row)}
                        >
                            {hasNoValueToSelect && <WarningColorSwitcher style={{ width: "1.5rem" }} warning />}
                            <span className="text-ellipsis">
                                <span className="bold">{row.original.generatedID} /&nbsp;</span>
                                <span className="fade">{createFullName(row.original.first_name, row.original.last_name)}</span>
                            </span>
                        </Tooltip>
                    </div>
                );
            }
        },
        ACTION: {
            key: "action",
            width: "25%",
            render: (row, _, checkedIds) => {
                const isKeep = checkedIds.includes(row.id);
                if (isKeep) {
                    return null;
                }
                return (
                    <Button onClick={() => typeof onSelect === "function" && onSelect(row)} options={{ style: { padding: 0 } }} transparent>
                        <span className="bold" style={{ textDecoration: "underline" }}>
                            Modify Selection
                        </span>
                    </Button>
                );
            }
        },
        STATUS: {
            key: "status",
            label: (
                <div className="flex column">
                    <span>Selected</span>
                    <span>Row</span>
                </div>
            ),
            width: "10%",
            render: (row) => {
                const status = getStatusFromDuplicates(row.duplicates || []);
                return <Tag className={status.color}>{status.label}</Tag>;
            }
        },
        KEEP_ORIGINAL: {
            key: "keep",
            label: <span style={{ maxWidth: "min-content" }}>Keep Original</span>,
            width: "10%",
            isCheckbox: true,
            checked: true
        }
    };
    return { data: Object.values(headers), original: headers };
};

ManageDuplicates.propTypes = {
    employees: PropTypes.array,
    setEmployees: PropTypes.func,
    onKeepUploads: PropTypes.func,
    isAllKeepOriginal: PropTypes.bool,
    toBeInserted: PropTypes.number,
    usingCachedValue: PropTypes.bool
};

export default ManageDuplicates;
