import React, { useState } from "react";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { selectUser } from "../../common/slice";
import Button from "../../../common/components/extra/Button";
import Navigation from "../../../common/classes/Navigation";
import useNavigate from "../../../common/hooks/useNavigate";
import { convertToObject, renderNA } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import ResultModal from "./ResultModal";
import ButtonUpgrade from "../../../common/components/extra/ButtonUpgrade";
import { STEP } from "./const";

const RESULT_TYPE = {
    ORIGINAL: 0x1,
    MODIFIED: 0x2,
    IGNORED: 0x3
};

function BulkUploadResult({ result, ignoredRows }) {
    const navigate = useNavigate();

    const [object, setObject] = useState({
        openModal: false,
        resultType: null,
        result: null,
        ignoredRows
    });

    const user = useAppSelector(selectUser);

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    const temp = {
        keepOriginalRecords: result[STEP.STEP_2.idx]?.keepOriginalRecords || 0,
        inserted: result[STEP.STEP_2.idx]?.inserted || 0,
        modified: result[STEP.STEP_2.idx]?.modified || 0,
        employeeLimitReached: result[STEP.STEP_2.idx]?.employeeLimitReached || false,
        totalFileSize: result[STEP.STEP_4.idx]?.totalFileSize || 0,
        employees: result[STEP.STEP_2.idx]?.employees || [],
        summary: result[STEP.STEP_2.idx]?.summary
    };

    const companySubscription = user?.CompanySubscription?.Subscription;
    const employeeLimit = companySubscription?.employee_limit || 0;
    const isResultOriginal = object.resultType === RESULT_TYPE.ORIGINAL;
    const isResultIgnored = object.resultType === RESULT_TYPE.IGNORED;
    const noChangesTotal = temp.summary.totalNoChanges || 0;

    temp.employees = temp.employees.map((emp) => ({
        ...emp,
        original: { ...emp.original, ...(!emp.keepOriginal ? convertToObject(emp.selected)?.employee : {}) }
    }));
    const viewRecords = isResultIgnored ? ignoredRows : temp.employees.filter((emp) => (isResultOriginal ? emp.keepOriginal : emp.modified));

    return (
        <div className="tk-bulk-upload__result" style={{ display: "flex" }}>
            <div className="tk-bulk-upload__result__inner">
                <div className="tk-bulk-upload__result__header">
                    <div className="with-custom-tag with-custom-btn">
                        <h2>Final Result</h2>
                        <Button className="primary" onClick={() => navigate(Navigation.Routes.EMPLOYEES.subpaths.LIST.path)}>
                            <div className="flex gap-05 center">
                                <span>Goto {"Employee's"} Page</span>
                                <VisibilityIcon />
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="tk-bulk-upload__result__body">
                    <div className="update-records">
                        <div className="flex align-center gap-03">
                            <span className="fade">Total New Records:</span>
                            <span className="primary-color bold">{temp.inserted + " Records"}</span>
                        </div>
                        <span className="fade">Total Updated Records:</span>
                        <ul style={{ gap: ".5rem" }}>
                            <li>
                                <span className="fade">Retained:</span>
                                <span className="primary-color bold">{temp.keepOriginalRecords + " Records"}</span>
                                {!!temp.keepOriginalRecords && (
                                    <VisibilityIcon
                                        className="hover-svg"
                                        onClick={() => updateObject({ openModal: true, resultType: RESULT_TYPE.ORIGINAL })}
                                    />
                                )}
                            </li>
                            <li>
                                <span className="fade">Modified:</span>
                                <span className="primary-color bold">{temp.modified + " Records"}</span>
                                {!!temp.modified && (
                                    <VisibilityIcon
                                        className="hover-svg"
                                        onClick={() => updateObject({ openModal: true, resultType: RESULT_TYPE.MODIFIED })}
                                    />
                                )}
                            </li>
                            <li>
                                <span className="fade">Ignored:</span>
                                <span className="primary-color bold">{ignoredRows.length + " Records"}</span>
                                {!!ignoredRows.length && (
                                    <VisibilityIcon
                                        className="hover-svg"
                                        onClick={() => updateObject({ openModal: true, resultType: RESULT_TYPE.IGNORED })}
                                    />
                                )}
                            </li>
                            <li>
                                <span className="fade">No Changes:</span>
                                {noChangesTotal ? <span className="primary-color bold">{noChangesTotal + " Records"}</span> : renderNA(0)}
                            </li>
                        </ul>
                    </div>
                    <div className="file-size">
                        <span className="fade">Uploaded File Size:</span>
                        {temp.totalFileSize ? <span className="primary-color bold">{temp.totalFileSize}</span> : renderNA("N/A")}
                    </div>
                </div>
            </div>
            <div className="tk-bulk-upload__result__footer with-custom-btn">
                {temp.employeeLimitReached && (
                    <div className="tk-employees__warning flex gap-05">
                        <span>
                            You have reached your employee limit of <strong style={{ color: "inherit" }}> {employeeLimit}</strong>. Upgrade now to add
                            more.
                        </span>
                        <ButtonUpgrade />
                    </div>
                )}
            </div>
            {object.openModal && (
                <ResultModal
                    open={object.openModal}
                    onChange={(bool) => updateObject({ openModal: bool })}
                    title={`View ${isResultIgnored ? "Ignored Rows" : (isResultOriginal ? "Retained" : "Modified") + " Records"}`}
                    data={viewRecords}
                    isViewIgnoredRows={isResultIgnored}
                />
            )}
        </div>
    );
}

BulkUploadResult.propTypes = {
    result: PropTypes.object,
    step: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        stepNumber: PropTypes.number,
        name: PropTypes.string,
        error: PropTypes.bool,
        isDone: PropTypes.bool,
        retry: PropTypes.bool,
        isAttemptingNext: PropTypes.bool,
        canNext: PropTypes.bool,
        loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    ignoredRows: PropTypes.arrayOf(
        PropTypes.shape({
            employee: PropTypes.shape({
                residenceID: PropTypes.string,
                first_name: PropTypes.string,
                last_name: PropTypes.string
            }),
            rowNum: PropTypes.number
        })
    ),
    employees: PropTypes.arrayOf(PropTypes.string),
    onSuccess: PropTypes.func,
    updateStep: PropTypes.func,
    onRetry: PropTypes.func,
    active: PropTypes.bool,
    currentData: PropTypes.any
};

export default BulkUploadResult;
