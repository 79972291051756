import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import PlaceIcon from "@mui/icons-material/Place";
import { ROLE_LEVEL, ROLE_TYPE } from "../../../common/utilities/const";
import { renderNA, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { ASSIGN_TYPE } from "./const";
import Button from "../../../common/components/extra/Button";
import MyTooltip from "../../../common/components/extra/Tooltip";
import SeeMore from "../../../common/components/extra/SeeMore";
import UpdateFilesButton from "./UpdateFilesButton";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserCountrySettings, selectUserSetting } from "../../common/slice";
import Text from "../../../common/components/extra/typography/Text";
import UpdateInsuranceButton from "./UpdateInsuranceButton";

const MAX_SITES_TO_SHOW = 5;

const LazyTableHeaders = ({
    onViewRecord,
    onViewSites,
    onViewFile,
    readOnly,
    defaultCheckedId,
    enableUncheckedOnshift,
    type,
    title,
    workShiftId,
    disableSupervisors,
    showSites,
    selected,
    isExpiringWideUI
}) => {
    const isWorkShift = type === ASSIGN_TYPE.WORK_SHIFT;
    const isSite = type === ASSIGN_TYPE.SITE;
    const isForExpiringType = type === ASSIGN_TYPE.EXPIRING;
    const isForInsuranceExpiring = type === ASSIGN_TYPE.INSURANCE_EXPIRING;

    const countrySetting = useAppSelector(selectUserCountrySettings);
    const setting = useAppSelector(selectUserSetting);

    const supportedFileId = countrySetting?.file_id_confg?.supported || [];

    const isDisabledSelection = (row) => {
        const onShiftSiteId = row?.onShiftSiteId;
        const isSVorMGinSelectedSite =
            disableSupervisors && row.CompanySites.some((site) => site.id == selected.id && (site.isManager || site.isSupervisor));
        return (
            (defaultCheckedId.includes(row.id) && row.isOnShift) ||
            (!enableUncheckedOnshift && selected?.id && onShiftSiteId != selected.id && row.isOnShift && !defaultCheckedId.includes(row.id)) ||
            isSVorMGinSelectedSite
        );
    };

    const showFileTag = (validity = {}) => {
        return validity.isExpired || validity.isWarning;
    };

    const createFileTooltip = (validity = {}) => {
        const isExpired = validity.isExpired;
        const expiryDate = validity.expiryDate;
        const expiredMessage = `Already expired at ${toReadableFromDate(expiryDate, setting.timezone, "MMM DD, YYYY")}`;
        const warningMessage = `Will expire in ${toReadableFromDate(expiryDate, setting.timezone, "MMM DD, YYYY")}`;
        const message = isExpired ? expiredMessage : warningMessage;
        return { message };
    };

    const createTagClass = (validity = {}) => (validity.isExpired ? "red-inverse" : "yellow");

    const createInsurancesValidityTags = (insurances) => {
        if (isForExpiringType || !Array.isArray(insurances) || !insurances?.length || !isExpiringWideUI) {
            return null;
        }
        return insurances.map(
            (insurance, idx) =>
                showFileTag(insurance) && (
                    <Tag key={idx} className={createTagClass(insurance)} tooltip={createFileTooltip(insurance)}>
                        <span className="text-ellipsis">{insurance.id}</span>
                    </Tag>
                )
        );
    };

    const createDocumentValidityTags = (row) => {
        if (isForInsuranceExpiring || !isExpiringWideUI) {
            return null;
        }

        const hasValidityKeys = supportedFileId.map((field) => (field === "labor_card_number" ? "labor_card" : field));

        return (
            <div className="flex gap-05 column small-font">
                <span className="small-font fade">Expiring/Expired:</span>
                <div className="flex wrap gap-05">
                    {hasValidityKeys
                        .filter((validityKey) => !!row[validityKey]?.date && showFileTag(row[validityKey]))
                        .map((validityKey, idx) => {
                            const validity = row[validityKey];
                            return (
                                <Tag key={idx} className={createTagClass(validity)} tooltip={createFileTooltip(validity)}>
                                    <span className="text-ellipsis">{sanitizeWords(validityKey, "_").replace(" Id", " ID")}</span>
                                </Tag>
                            );
                        })}
                </div>
            </div>
        );
    };

    const renderActionByType = (row) => {
        switch (type) {
            case ASSIGN_TYPE.EXPIRING:
                return (
                    <UpdateFilesButton
                        id={row.id}
                        onClick={() => typeof onViewFile === "function" && onViewFile(row)}
                        transparentOverlay
                        useEditIcon
                        isIcon
                        noInternalModal
                    />
                );
            case ASSIGN_TYPE.INSURANCE_EXPIRING:
                return (
                    <UpdateInsuranceButton
                        id={row.id}
                        onClick={() => typeof onViewFile === "function" && onViewFile(row)}
                        transparentOverlay
                        useEditIcon
                        isIcon
                        noInternalModal
                    />
                );
            default:
                return null;
        }
    };

    const headers = {
        CHECKBOX: {
            key: "id",
            width: "10%",
            style: { justifyContent: "flex-start" },
            render: () => "",
            isCheckbox: !readOnly,
            disableSelection: isDisabledSelection
        },
        EMPLOYEE: {
            key: "index1",
            sortKey: "index1",
            label: title || "Employee",
            width: "70%",
            render: (row) => {
                const fullName = `${row.first_name} ${row.last_name}`;
                const department = row.CompanyDepartment;
                const designation = row.CompanyDesignation;
                const isSuperVisor = row.Role && row.Role?.type == ROLE_TYPE.EMPLOYEE && row.Role?.level == ROLE_LEVEL.HIGH;
                const selectionCannotBeRemoved = isDisabledSelection(row);

                return (
                    <div className="flex gap-1">
                        <div className="flex column gap-05">
                            <div className="flex column gap-05" style={{ justifyContent: "center" }}>
                                <div
                                    className="link-hover"
                                    style={{ fontWeight: "bold", width: "max-content" }}
                                    onClick={() => typeof onViewRecord === "function" && onViewRecord(row)}
                                >
                                    {sanitizeWords(fullName)}
                                </div>

                                <div className="flex gap-05 wrap">
                                    {isWorkShift &&
                                        !defaultCheckedId.includes(row.id) &&
                                        row?.EmployeeWorkShift &&
                                        workShiftId == row?.EmployeeWorkShift?.id && <Tag className="red">{row?.EmployeeWorkShift?.title}</Tag>}
                                    {row.isOnShift && <Tag type={TAG_TYPE.ON_SHIFT} />}
                                    {isSuperVisor && <Tag type={TAG_TYPE.APP_SUPERVISOR} />}
                                </div>
                            </div>
                            {createDocumentValidityTags(row)}
                            {createInsurancesValidityTags(row.insurances)}
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Department:</span>
                                <span className="semi-bold">{sanitizeWords(department?.title) || renderNA("No Department.")}</span>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Designation:</span>
                                <span className="semi-bold">{sanitizeWords(designation?.title) || renderNA("No Designation.")}</span>
                            </div>
                            {isSite && showSites && !!row?.CompanySites?.length && (
                                <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                    <span className="small-font fade">Sites:</span>
                                    <SeeMore max={MAX_SITES_TO_SHOW}>
                                        {row?.CompanySites.map((site, idx) => (
                                            <MyTooltip key={idx} message={site.title}>
                                                <Tag className={site.id == selected.id ? "green" : ""} style={{ maxWidth: "7rem" }}>
                                                    <span className="text-ellipsis">{site.title}</span>
                                                </Tag>
                                            </MyTooltip>
                                        ))}
                                    </SeeMore>
                                </div>
                            )}
                            {selectionCannotBeRemoved && (
                                <Text useSubTextStyle>Notice: You cannot remove an employee who is a supervisor/manager for this site.</Text>
                            )}
                        </div>
                    </div>
                );
            }
        },
        ACTION: {
            key: "action",
            width: readOnly ? "30%" : "20%",
            style: { display: "flex", justifyContent: "flex-end" },
            render: (row) => {
                return (
                    <div className="flex gap-05">
                        <Button
                            onClick={() => typeof onViewRecord === "function" && onViewRecord(row)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <div className="fade">
                                <PreviewIcon style={{ width: "1.5rem" }} />
                            </div>
                        </Button>
                        {renderActionByType(row)}
                        {!isExpiringWideUI && (
                            <Button
                                onClick={() => typeof onViewSites === "function" && onViewSites(row)}
                                options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                transparent
                            >
                                <div className="fade">
                                    <PlaceIcon style={{ width: "1.5rem" }} />
                                </div>
                            </Button>
                        )}
                    </div>
                );
            }
        }
    };
    if (readOnly) {
        delete headers.CHECKBOX;
    }
    return { data: Object.values(headers), original: headers };
};

export default LazyTableHeaders;
