import React from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { createGroup } from "../../../common/utilities/helper";
import { DEFAULT_INSURANCE_FIELDS, EMPLOYEE_INSURANCE_TYPE, INSURANCE_FIELDS } from "./const";
import InputDynamicList from "../../../common/components/extra/form/InputDynamicList";
import InsuranceInputs from "./InsuranceInputs";

function UpdateInsuranceInputs({ base, insurances, isGettingEmployee, onChange, error, setError }) {
    const transformRenderForInsurance = (item, groupIdx) => {
        const handleValueChange = (e) => {
            if (error && error.map((value, index) => index).includes(groupIdx)) {
                setError({
                    ...(error || {}),
                    EmployeeInsurances: error.filter((value, index) => index !== groupIdx)
                });
            }
            const name = e.target.name;
            const value = e.target.value;
            const temp = cloneDeep(insurances || []);

            temp[groupIdx][name] = value;

            if (value && name && name === INSURANCE_FIELDS.TYPE && value === EMPLOYEE_INSURANCE_TYPE.IOLE) {
                temp[groupIdx][INSURANCE_FIELDS.PLAN] = null;
            }

            onChange?.(temp);
        };
        return (
            <div className="flex column gap-05 w100" style={{ padding: ".5rem" }}>
                <InsuranceInputs index={groupIdx} form={item} onChange={handleValueChange} oldInsurances={insurances || []} />
            </div>
        );
    };
    return (
        <>
            {createGroup({
                base,
                title: "Insurance",
                body: (
                    <>
                        <InputDynamicList
                            styles={{ parent: { marginTop: ".5rem" } }}
                            defaultObject={DEFAULT_INSURANCE_FIELDS}
                            addLabel="Add Insurance"
                            value={insurances || []}
                            onChange={(value) => onChange(value)}
                            transformRender={transformRenderForInsurance}
                            errors={
                                error &&
                                error.reduce(
                                    (prev, curr) => ({
                                        ...prev,
                                        [curr.index]: curr.message
                                    }),
                                    {}
                                )
                            }
                            removeLabel="Remove Insurance"
                            isLoading={isGettingEmployee}
                            useSubBorderColor
                        />
                    </>
                )
            })}
        </>
    );
}

export default UpdateInsuranceInputs;

UpdateInsuranceInputs.propTypes = {
    base: PropTypes.string,
    insurances: PropTypes.array,
    isGettingEmployee: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.array,
    setError: PropTypes.func
};
