import React, { useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { BASE_CLASS_UPDATE_MODAL } from "./const";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { useUpdateEmployeeInsurance } from "./hooks";
import { TOAST_TYPE, createClass, createToast, formattedJoiErrorMessage, sanitizeWords } from "../../../common/utilities/helper";
import UpdateInsuranceInputs from "./UpdateInsuranceInputs";

function UpdateInsuranceModal({ open, onClose, onBack, onFinish, id, transparentOverlay }) {
    const [error, setError] = useState(null);

    const [updateInsurance, isLoading, { onInsuranceChange, insurances, current, isGettingEmployee }] = useUpdateEmployeeInsurance(id);

    const handleSave = async () => {
        try {
            const result = await updateInsurance();

            if (result) {
                createToast(
                    `Employee [${sanitizeWords(result?.first_name)} ${sanitizeWords(result?.last_name)}] insurance updated succesfully.`,
                    TOAST_TYPE.SUCCESS
                );
            } else {
                createToast(result.data.message, TOAST_TYPE.SUCCESS);
            }
            if (result && typeof onFinish === "function") {
                onFinish(result);
            }
            setError(null);
            onBack?.();
        } catch (error) {
            setError({ message: error?.message || error });
            createToast(`Failed to Update Employee insurance!. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return (
        <BaseUpdateModal
            title="Update Insurance"
            open={open}
            onClose={onClose}
            onSave={handleSave}
            isLoading={isLoading}
            disableSave={isLoading || isGettingEmployee || isEqual(insurances, current.EmployeeInsurances)}
            styles={{
                content: {
                    margin: "1rem",
                    minWidth: "25rem",
                    maxWidth: "40rem"
                },
                body: {
                    overflow: "auto"
                }
            }}
            transparentOverlay={transparentOverlay}
            onBack={onBack}
            isForm
            small
        >
            <div className={createClass(BASE_CLASS_UPDATE_MODAL)} style={{ margin: "0 1rem" }}>
                <div className={createClass("__inner", BASE_CLASS_UPDATE_MODAL)}>
                    <SectionCollapseError show={!!error}>{formattedJoiErrorMessage({ error, isCreate: false })}</SectionCollapseError>
                    <div className={createClass("__inner-row", BASE_CLASS_UPDATE_MODAL)} style={{ marginTop: "1rem" }}>
                        <UpdateInsuranceInputs
                            base={BASE_CLASS_UPDATE_MODAL}
                            insurances={insurances}
                            current={current}
                            isGettingEmployee={isGettingEmployee}
                            onChange={onInsuranceChange}
                            error={(Array.isArray(error) && error) || []}
                            setError={setError}
                        />
                    </div>
                </div>
            </div>
        </BaseUpdateModal>
    );
}

UpdateInsuranceModal.propTypes = {
    id: PropTypes.any,
    photo: PropTypes.string,
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    transparentOverlay: PropTypes.bool
};

export default UpdateInsuranceModal;
