import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import { ASSIGN_TYPE, CONTRACT_ALLOWANCE_FIELDS, DATE_STATUS } from "./const";
import { isNumber } from "lodash";
import { toProperTimezoneConversion } from "../../../common/utilities/helper";
import { FILE_EXIRY_IN_DAYS } from "../../../common/utilities/const";

export const getInsuranceDateStatus = (data, timezone) => {
    if (!data?.coverage_expiration) {
        return { value: DATE_STATUS.NORMAL, className: "success-color" };
    }
    const end = toProperTimezoneConversion(data.coverage_expiration, timezone).startOf("day");
    const today = toProperTimezoneConversion(new Date(), timezone).startOf("day");
    const assumedEndDateWithWarning = toProperTimezoneConversion(data.coverage_expiration, timezone)
        .endOf("day")
        .subtract(Number(FILE_EXIRY_IN_DAYS), "days");

    if (today.isSameOrAfter(end)) {
        return {
            value: DATE_STATUS.EXPIRED,
            className: "danger-color"
        };
    }
    if (today.isSameOrAfter(assumedEndDateWithWarning)) {
        return {
            value: DATE_STATUS.EXPIRING,
            remainingDays: Math.round(moment(end).diff(today, "days", true)),
            className: "warning-color"
        };
    }
    return { value: DATE_STATUS.NORMAL, className: "success-color" };
};

export const computeTotalSalaryPackage = (basicAmount, allowance) => {
    let total = 0;
    const newAllowance = cloneDeep(allowance);
    const notIncludedAllowance = [CONTRACT_ALLOWANCE_FIELDS.AIR_TICKET];
    for (const key in newAllowance) {
        if (Object.prototype.hasOwnProperty.call(newAllowance, key)) {
            const allowanceValue = Number(newAllowance[key]);
            if (!notIncludedAllowance.includes(key)) {
                total += isNumber(allowanceValue) ? allowanceValue : 0;
            }
        }
    }
    return basicAmount + total;
};

export const customSortKeys = (a, b, priorityKeys = []) => {
    // Define the order of categories
    const order = ["priority", "ID", "contact", "personal", "work", "bank", "other"];

    // Function to get category of a key
    function getCategory(key) {
        if (priorityKeys.includes(key)) return "priority";
        if (key.endsWith("ID")) return "ID";
        if (key.includes("mobile_number") || key.includes("email_address")) return "contact";
        if (key.startsWith("employee.")) return "personal";
        if (key.startsWith("contract.")) return "work";
        if (key.startsWith("bank.")) return "bank";
        return "other";
    }

    // Get category of each key
    const categoryA = getCategory(a);
    const categoryB = getCategory(b);

    // Compare categories
    const indexA = order.indexOf(categoryA);
    const indexB = order.indexOf(categoryB);

    if (indexA !== indexB) {
        return indexA - indexB;
    }

    // If both keys belong to the same category, sort them alphabetically
    return a.localeCompare(b);
};

export const createDefaultCheckId = (arr = [], data, type) => {
    switch (type) {
        default:
        case ASSIGN_TYPE.DEFAULT: {
            return arr.filter((emp) => (data || []).includes(emp.id)).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.WORK_SHIFT: {
            return arr.filter((emp) => emp.work_shift_id === data.id).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.SITE: {
            return arr.filter((emp) => emp.CompanySites.map((cs) => cs.id).includes(data.id)).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.EXPIRING: {
            return [];
        }
    }
};

export const getEmployeeFileValidity = (expiry, timezone) => {
    if (!expiry) {
        return {};
    }
    const warningPeriod = Number(FILE_EXIRY_IN_DAYS);
    const today = moment().tz(timezone);
    const todayWithWarningPeriod = moment(today).tz(timezone).add(warningPeriod, "days");
    const expiryDate = moment(expiry).tz(timezone);
    const isExpired = today.isAfter(expiryDate);
    const isWarning = !isExpired && todayWithWarningPeriod.isAfter(expiryDate);
    return {
        isExpired,
        isWarning,
        date: expiryDate.format(),
        fromNow: expiryDate.fromNow()
    };
};
