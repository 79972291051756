import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import FileUploader from "../../../common/components/extra/fileUploader/FileUploader";
import { FILE_MIME_TYPES } from "../../../common/utilities/const";
import { ERROR_TYPE, FILE_STATES } from "../../../common/components/extra/fileUploader/const";
import { TOAST_TYPE, createToast, sanitizeWords } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { BASE_PATH_URI } from "../../../app/apiSlice";
import { selectToken, selectUser } from "../../common/slice";
import { ERROR_CODE } from "./const";

const UPLOAD_ENDPOINT = BASE_PATH_URI + "/api/company/employee/bulk-steps/upload-csv";
const FILE_SIZE_LIMIT = 5; // 5MB

function Component({ onSuccess, onUpload, onRemoveUploads, onStart, onFinish, onError, onWarning, error, warning, value }, uploadRef) {
    const user = useAppSelector(selectUser);
    const token = useAppSelector(selectToken);

    const companySubscription = user?.CompanySubscription?.Subscription;
    const employeeLimit = companySubscription?.employee_limit || 0;

    const createCustomError = (obj = {}) => {
        const data = obj.data;

        switch (obj.code) {
            case ERROR_CODE.INVALID_CSV_FIELDS:
                return (
                    <ul className="flex column gap-05">
                        Please check the following errors and retry again to proceed to the next step.
                        {data
                            .map((item) => item.errors.map((error) => error.message))
                            .flat()
                            .map((error, idx) => (
                                <li key={idx} className="has-list-style" style={{ listStyle: "disc" }}>
                                    <div className="flex gap-03 wrap">{error}</div>
                                </li>
                            ))}
                    </ul>
                );
            case ERROR_CODE.NOT_ALLOWED_UNIQUE_VALUES:
                return (
                    <ul className="flex column gap-05">
                        The following values are not allowed and is already taken.
                        {Object.keys(data).map((key) => (
                            <li key={key} className="has-list-style" style={{ listStyle: "disc" }}>
                                <div className="flex gap-03 wrap">
                                    <span>{sanitizeWords(key.replace("ID", "_ID"), "_").replace(" Id", " ID")}: </span>
                                    {data[key].map((val, idx) => (
                                        <div key={idx} className="primary-color semi-bold">
                                            {val}
                                            {idx !== data[key].length - 1 && ","}
                                        </div>
                                    ))}
                                </div>
                            </li>
                        ))}
                    </ul>
                );
            case ERROR_CODE.DUPLICATE_UNIQUE_VALUES_ACROSS_DB:
                return (
                    <ul className="flex column gap-05">
                        The following values are not allowed and is already taken.
                        {Object.entries(data).map(([key, value]) => (
                            <li key={key} className="has-list-style" style={{ listStyle: "disc" }}>
                                <div className="flex gap-03 wrap">
                                    <span>Row {key}: </span>
                                    {value.map((val, idx) => (
                                        <div key={idx} className="primary-color semi-bold">
                                            {`"${val.record[val.conflictingKey]}"${(idx !== value.length - 1 && ",") || ""}`}
                                        </div>
                                    ))}
                                </div>
                            </li>
                        ))}
                    </ul>
                );
            default:
                return (
                    <ul className="flex column gap-05">
                        <span>Something went wrong, Please try again later.</span>
                    </ul>
                );
        }
    };

    const createIgnoreEmployeeWarningError = (rows) => {
        return (
            <ol className="flex column gap-05" style={{ paddingLeft: "1rem" }}>
                <li>
                    The current plan allows for a maximum of <strong style={{ color: "inherit" }}>{employeeLimit}</strong> employees. Upgrade to add
                    more.
                </li>
                <li>
                    <span>The following rows will not be inserted and ignored: </span>
                    <ul>
                        <li style={{ fontWeight: "600" }}>{rows.map((r) => r.rowNum).join(", ")}</li>
                    </ul>
                </li>
            </ol>
        );
    };

    const uploadConfig = {
        config: { method: "POST", autoUpload: true },
        destination: {
            url: UPLOAD_ENDPOINT,
            headers: { authorization: `Bearer ${token}` }
        }
    };

    const handleUploadAdd = (batch) => {
        onError?.(null);
        onWarning?.(null);
        typeof onUpload === "function" && onUpload(batch);
    };

    const handleUploadComplete = async (err, batch) => {
        const item = (Array.isArray(batch.items) && batch.items.length > 0 && batch.items[0]) || {};
        const response = item?.uploadResponse?.data || {};
        let message = response.message;
        const hasCustomError = !!message.error;
        const errorResponse = response.status == 0;
        const cancelled = FILE_STATES.CANCELLED === item.state;

        // apply common error for failed
        if ((err && errorResponse) || cancelled || hasCustomError) {
            const isError = item.state === FILE_STATES.ERROR || cancelled || hasCustomError;
            if (hasCustomError) {
                message = createCustomError(message.error);
            }
            if (isError) {
                handleError({
                    type: ERROR_TYPE.CUSTOM,
                    code: "custom",
                    message: (
                        <ul>
                            <li
                                key={item.id}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto 1fr",
                                    columnGap: ".5rem"
                                }}
                            >
                                <span style={{ fontWeight: 600 }}>File:</span>
                                <span>{item.file.name}</span>
                                <span style={{ fontWeight: 600 }}>Error:</span>
                                <span>{message || (cancelled && "Upload failed, file has been interrupted.") || "Upload failed."} </span>
                            </li>
                        </ul>
                    )
                });
            } else {
                onFinish();
            }
            return { reset: true };
        }

        if (!!message.ignoredEmployees.length && !warning) {
            handleWarning(createIgnoreEmployeeWarningError(message.ignoredEmployees));
            createToast(
                "Warning: The maximum limit of employees has been reached for the current plan. Some rows will be ignored.",
                TOAST_TYPE.WARNING
            );
        } else {
            handleWarning(null);
        }

        typeof onSuccess === "function" &&
            onSuccess({
                batch,
                employees: message.employees || [],
                ignoredEmployees: message.ignoredEmployees || [],
                hasWarning: !!message.ignoredEmployees.length,
                token: message.token
            });
        onFinish?.(batch);
        handleError(null);
    };

    const handleError = (error) => {
        onError?.(error);
        onFinish();
    };

    const handleWarning = (warning) => {
        onWarning?.(warning);
    };

    const handleRemove = () => {
        typeof onRemoveUploads === "function" && onRemoveUploads();
    };

    return (
        <div className="tk-bulk-upload__excel" style={{ display: "flex" }}>
            <FileUploader
                ref={uploadRef}
                value={value}
                onItemStart={onStart}
                accepts={[...Object.values(FILE_MIME_TYPES.EXCEL).flat()]}
                maxFileSize={FILE_SIZE_LIMIT}
                onUploadComplete={handleUploadComplete}
                onAdd={handleUploadAdd}
                error={error}
                warning={warning}
                onError={handleError}
                onRemove={handleRemove}
                instruction="Please make sure that you follow the required format for the excel file before uploading."
                {...uploadConfig}
            />
        </div>
    );
}

const BulkUploadExcelFile = forwardRef(Component);

Component.propTypes = {
    onSuccess: PropTypes.func,
    onUpload: PropTypes.func,
    onRemoveUploads: PropTypes.func,
    onStart: PropTypes.func,
    onFinish: PropTypes.func,
    onError: PropTypes.func,
    onWarning: PropTypes.func,
    error: PropTypes.any,
    warning: PropTypes.any,
    value: PropTypes.any
};

export default BulkUploadExcelFile;
