import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import createUploader, { UPLOADER_EVENTS, composeEnhancers, UploaderType, SendOptions, UploadInfo, BatchItem } from "@rpldy/uploader";
import { getMockSenderEnhancer } from "@rpldy/mock-sender";
import retryEnhancer from "@rpldy/retry-hooks";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import FileUpload from "./FileUpload";
import { FILE_MIME_TYPES } from "../../../utilities/const";
import { TOAST_TYPE, acceptsToReadable, createToast, megabytesToBytes } from "../../../utilities/helper";
import { ERROR_TYPE, BATCH_STATES, FILE_STATES } from "./const";
import SectionCollapse from "../section/SectionCollapse";
import Tooltip from "../Tooltip";

const mockEnhancer = getMockSenderEnhancer({ delay: 2000 });
const enhancer = composeEnhancers(retryEnhancer, mockEnhancer);

const { ITEM_START, BATCH_ADD, BATCH_PROGRESS, BATCH_ABORT, BATCH_FINALIZE, ITEM_ERROR } = UPLOADER_EVENTS;

const COMMON_SECTION_STYLES = {
    header: { fontWeight: "600" },
    parent: { width: "100%", padding: "0 2rem" }
};

export default class FileUploader extends Component {
    #uploader = null;
    #isFolder = false;
    #defConfig = {
        destination: { url: "" },
        withCredentials: true,
        multiple: false,
        autoUpload: false,
        clearPendingOnAdd: true,
        sendWithFormData: true
    };
    constructor(props) {
        super(props);

        this.processUploadsRef = createRef();

        this.#isFolder = this.props.accepts.includes(FILE_MIME_TYPES.FOLDER);

        if (this.#isFolder) {
            this.#defConfig.webkitdirectory = true;
            this.#defConfig.grouped = true;
            this.#defConfig.maxGroupSize = 100;
        }
        this.#defConfig = {
            ...this.#defConfig,
            ...this.props.config
        };
        this.#defConfig.destination = {
            ...this.#defConfig.destination,
            ...this.props.destination
        };

        if (this.props.debug) {
            this.#defConfig.debug = true;
            this.#defConfig.enhancer = enhancer;
        }

        this.state = {
            batch: this.props.value || null,
            loading: {
                state: BATCH_STATES.PENDING,
                percent: 0
            },
            error: null
        };
    }

    /**
     * @returns {UploaderType}
     */
    get Uploader() {
        return this.#uploader;
    }
    /**
     * @returns {BatchItem}
     */
    get Batch() {
        return this.state.batch;
    }
    /**
     * @returns {Array<UploadInfo>}
     */
    get Files() {
        return (this.state.batch && this.state.batch.items) || [];
    }
    /**
     * @returns {Number}
     */
    get FilesCompleted() {
        return this.Files.filter((file) => file.state === FILE_STATES.FINISHED).length || 0;
    }
    /**
     * @returns {{state: BATCH_STATES, percent: number }}
     */
    get Loading() {
        return this.state.loading;
    }

    componentDidMount() {
        const { accepts, maxFileSize } = this.props || {};
        this.#uploader = createUploader({
            ...this.#defConfig,
            fileFilter: (file) => this.fileValidation(file, accepts, this.#isFolder, maxFileSize)
        });
        this.listeners();
    }

    componentDidUpdate(prevProps) {
        // track error props changes and make the error state the same as the props
        if (prevProps.error !== this.props.error && this.state.error !== this.props.error) {
            this.setState({ error: this.props.error });
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    setBatch(newBatch) {
        this.setState({ batch: newBatch });
    }

    /**
     * @param {Array<UploadInfo>} files
     * @param {SendOptions} newOptions
     */
    async addFiles(files, newOptions = {}) {
        return this.Uploader.add(files, newOptions);
    }

    /**
     * @param {SendOptions} newOptions
     */
    processUploads(newOptions = {}) {
        this.Uploader.upload(newOptions);
    }

    listeners() {
        this.Uploader.on(ITEM_START, (item) => {
            if (typeof this.props.onItemStart === "function") {
                const response = this.props.onItemStart(item);
                if (typeof response === "boolean") {
                    return response; // if false it will cancel the item;
                }
            }
        });
        this.Uploader.on(BATCH_ADD, (batch) => {
            let batchToAdd = batch;
            if (typeof this.props.onAdd === "function") {
                const newbatch = this.props.onAdd(batch);
                if (typeof newbatch === "object" && newbatch.id === batch.id) {
                    batchToAdd = newbatch;
                } else if (newbatch === false) {
                    batchToAdd = null;
                }
            }
            this.setState({ batch: batchToAdd });
        });
        this.Uploader.on(BATCH_PROGRESS, (batch) => {
            if (batch.id === this.state.batch?.id) {
                const rounded = Math.floor(batch.completed * 100);
                const percent = rounded > 100 ? 100 : rounded;
                this.setState((prev) => ({ batch, loading: { ...prev.loading, percent, state: BATCH_STATES.PROCESSING } }));
                this.props?.onProcessing?.(batch);
            }
        });
        this.Uploader.on(BATCH_ABORT, (batch) => {
            if (batch.id === this.state.batch?.id) {
                this.setState((prev) => ({ batch, loading: { ...prev.loading, state: BATCH_STATES.ABORTED } }));
            }
        });
        this.Uploader.on(BATCH_FINALIZE, async (batch) => {
            if (batch.id === this.state.batch?.id) {
                let additonalConfig = {},
                    temp = batch;
                temp.items = temp.items.filter((it) => it.state === FILE_STATES.FINISHED);
                const errorItems = batch.items.filter((it) => it.state === FILE_STATES.ERROR);
                if (errorItems.length) {
                    const isSameLen = errorItems.length === batch.items.length;
                    if (isSameLen) {
                        temp = null;
                    }
                    additonalConfig.error = {
                        type: ERROR_TYPE.SERVER_ERROR,
                        code: "server-error",
                        message: "Something went wrong. Please try again later or contact support."
                    };
                }
                const res = await this.props?.onUploadComplete?.(additonalConfig.error, batch);
                if (res?.reset) {
                    this.setState(() => ({
                        batch: null,
                        loading: { percent: 0, state: BATCH_STATES.ERROR }
                    }));
                } else {
                    this.setState((prev) => ({
                        batch: temp,
                        loading: { ...prev.loading, state: batch.state },
                        ...additonalConfig
                    }));
                }
            }
        });
        this.Uploader.on(ITEM_ERROR, (data) => {
            const uploadResponse = data?.uploadResponse;
            const isStatusError = uploadResponse?.data?.status === 0;
            const errorMsg = isStatusError && (uploadResponse?.data?.message || "Something went wrong. Please try again later or contact support.");
            const error = {
                type: ERROR_TYPE.SERVER_ERROR,
                code: "server-error",
                message: errorMsg
            };
            this.setState(() => ({ batch: null, loading: { percent: 0, state: BATCH_STATES.ERROR }, error }));
            typeof this.props.onError === "function" && this.props.onError(error);
        });
    }

    unlisten() {
        this.Uploader.off(ITEM_START);
        this.Uploader.off(BATCH_ADD);
        this.Uploader.off(BATCH_PROGRESS);
        this.Uploader.off(BATCH_FINALIZE);
        this.Uploader.off(BATCH_ABORT);
        this.Uploader.off(ITEM_ERROR);
    }

    async fileValidation(file, accepts, isFolder, maxFileSize) {
        if (!file || !file.type) {
            return false;
        }
        const mimeType = file.type;
        const size = file.size;
        try {
            let acceptsTemp = accepts;
            if (isFolder) {
                acceptsTemp = [...Object.values(FILE_MIME_TYPES.IMAGE), FILE_MIME_TYPES.PDF];
            }
            const readableAccepts = acceptsToReadable(acceptsTemp);
            if (!acceptsTemp.includes(mimeType) && !this.props.noFileTypeCheck) {
                throw new Error(
                    JSON.stringify({
                        type: ERROR_TYPE.FILE_TYPE,
                        code: "invalid-file-type",
                        message: `Invalid file type. Only supports: [${readableAccepts.join(", ")}]`
                    })
                );
            }
            if (size > megabytesToBytes(maxFileSize) && !this.props.noFileSizeCheck) {
                throw new Error(
                    JSON.stringify({
                        type: ERROR_TYPE.FILE_SIZE,
                        code: "invalid-file-size",
                        message: `Invalid file size. Must be less than ${maxFileSize} MB.`
                    })
                );
            }
        } catch (err) {
            const parsedError = JSON.parse(err.message);
            createToast(parsedError.message, TOAST_TYPE.ERROR);
            this.setState({
                batch: null,
                loading: { percent: 0, state: BATCH_STATES.ERROR },
                error: parsedError
            });
            typeof this.props.onError === "function" && this.props.onError(parsedError);
            return false;
        }
        this.setState({ error: null });
        return true;
    }

    handleDropError(droperr) {
        this.setState({ batch: null, loading: { percent: 0, state: BATCH_STATES.ERROR }, error: droperr });
        typeof this.props.onError === "function" && this.props.onError(droperr);
    }

    handleRemove() {
        if (this.#isFolder) {
            this.setState(
                { loading: { percent: 0, state: BATCH_STATES.PENDING } },
                () => typeof this.props.onRemove === "function" && this.props.onRemove()
            );
        } else {
            typeof this.props.onRemove === "function" && this.props.onRemove();
        }
    }

    render() {
        return (
            <FileUpload
                ref={this.processUploadsRef}
                instance={this.Uploader}
                uploader={this}
                batch={this.Batch}
                files={this.Files}
                isFolder={this.#isFolder}
                error={this.state.error}
                accepts={this.props.accepts}
                onDropError={(err) => this.handleDropError(err)}
                style={this.props.style}
                onRemove={this.handleRemove.bind(this)}
            >
                {this.props.instruction &&
                    (this.Batch ? (
                        <>
                            <Tooltip
                                className="info-uploader hover-svg"
                                message={this.props.instruction}
                                position="bottom"
                                messageStyle={{ maxWidth: "20rem" }}
                            >
                                <InfoIcon />
                            </Tooltip>
                        </>
                    ) : (
                        <SectionCollapse
                            header={
                                <div className="flex center gap-05">
                                    <InfoIcon /> Instructions:
                                </div>
                            }
                            styles={{
                                header: COMMON_SECTION_STYLES.header,
                                parent: { ...COMMON_SECTION_STYLES.parent, backgroundColor: "#E1EDFF" }
                            }}
                            collapse
                        >
                            {this.props.instruction}
                        </SectionCollapse>
                    ))}
                {this.state.error && (
                    <SectionCollapse
                        header={
                            <div className="flex center gap-05">
                                <ErrorIcon style={{ color: "red" }} /> Error Message:
                            </div>
                        }
                        styles={{
                            header: COMMON_SECTION_STYLES.header,
                            parent: { ...COMMON_SECTION_STYLES.parent, backgroundColor: "#FFD9DA", color: "red" }
                        }}
                        collapse
                        alwaysOpen
                    >
                        <div className="small-font semi-bold">
                            {(() => {
                                switch (this.state.error?.type) {
                                    default:
                                    case ERROR_TYPE.SERVER_ERROR:
                                    case ERROR_TYPE.INVALID_DROP:
                                    case ERROR_TYPE.FILE_TYPE:
                                    case ERROR_TYPE.FILE_SIZE: {
                                        return this.state.error.message;
                                    }
                                    case ERROR_TYPE.FOLDER_FORMAT: {
                                        return this.state.error.message;
                                    }
                                }
                            })()}
                        </div>
                    </SectionCollapse>
                )}
                {this.props.warning && (
                    <SectionCollapse
                        header={
                            <div className="flex center gap-05">
                                <WarningIcon style={{ color: "#937409" }} /> Warning Message:
                            </div>
                        }
                        styles={{
                            header: COMMON_SECTION_STYLES.header,
                            parent: { ...COMMON_SECTION_STYLES.parent, backgroundColor: "#FFEBA4", color: "#937409" }
                        }}
                        collapse
                        alwaysOpen
                    >
                        <div className="small-font semi-bold">{this.props.warning}</div>
                    </SectionCollapse>
                )}
            </FileUpload>
        );
    }
}

FileUploader.propTypes = {
    config: PropTypes.object,
    value: PropTypes.any,
    destination: PropTypes.shape({
        url: PropTypes.string,
        params: PropTypes.object,
        headers: PropTypes.object
    }),
    accepts: PropTypes.arrayOf(
        PropTypes.oneOf(
            Object.values(FILE_MIME_TYPES)
                .map((fm) => (typeof fm === "object" && Object.values(fm)) || fm)
                .flat()
        )
    ),
    instruction: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    error: PropTypes.shape({
        type: PropTypes.any,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
        code: PropTypes.string
    }),
    onError: PropTypes.func,
    maxFileSize: PropTypes.number,
    debug: PropTypes.bool,
    noFileTypeCheck: PropTypes.bool,
    noFileSizeCheck: PropTypes.bool,
    onUploadComplete: PropTypes.func,
    onAdd: PropTypes.func,
    onItemStart: PropTypes.func,
    onRemove: PropTypes.func,
    onProcessing: PropTypes.func,
    style: PropTypes.object,
    warning: PropTypes.any,
    startUploadOnAdd: PropTypes.bool
};

FileUploader.defaultProps = {
    accepts: [],
    maxFileSize: 1,
    destination: {}
};
