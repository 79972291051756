import { STATUS } from "../employees/const";

export const getStatusFromDuplicates = (duplicates = []) => {
    const dups = duplicates;
    const hasAcceptedDups =
        dups.map((d) => ({ ...(d?.accepted || {}), row: d.rowNum })).filter((d) => Object.keys(d).filter(Boolean).length > 1) || [];
    const isMixed = hasAcceptedDups.length > 1;
    const isCustom = hasAcceptedDups.length === 1;
    const isOriginal = hasAcceptedDups.length <= 0;
    const status = isMixed ? STATUS.MIXED : isCustom ? STATUS.CUSTOM : isOriginal ? STATUS.ORIGINAL : STATUS.ORIGINAL;
    if (status.key === STATUS.CUSTOM.key) {
        // if status becomes custom it will contain 1 value anyway so we will get the first row only
        status.label = "ROW " + hasAcceptedDups[0].row;
    }
    return status;
};

export const getHasNoValueToSelect = (duplicates = []) => {
    return duplicates.every((duplicate) => {
        const { changes, notAllowedChangesKeyToUse } = {
            changes: duplicate.employee.changes,
            notAllowedChangesKeyToUse: duplicate.notAllowedChangesKeyToUse
        };
        return Object.keys(changes).every((key) => notAllowedChangesKeyToUse.includes(key));
    });
};
