import React, { useCallback, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import ClearIcon from "@mui/icons-material/Clear";
import AbortIcon from "@mui/icons-material/DoDisturb";
import RestartIcon from "@mui/icons-material/RestartAlt";
import FolderIcon from "@mui/icons-material/Folder";
import WarningIcon from "@mui/icons-material/Warning";
import { BATCH_STATES, FILE_STATES } from "./const";
import Button from "../Button";
import DropZone from "./DropZone";
import { bytesToReadable } from "../../../utilities/helper";
import { FILE_MIME_TYPES } from "../../../utilities/const";

const isItemError = (state) => state === FILE_STATES.ABORTED || state === FILE_STATES.ERROR;
const isBatchItemProgress = (state) => state === BATCH_STATES.UPLOADING || state === BATCH_STATES.PROCESSING;

const PreviewItem = ({ name, icon, size, loadingPercent, onAbort, onRetry, onRemove, noAbort, noRetry, subExtra, isError }) => {
    return (
        <div className={`tk-file-upload__preview ${isError ? "error" : ""}`}>
            <div className="tk-file-upload__preview__item">
                <div className="top">
                    <div className="left">{icon}</div>
                    <div className="right">
                        <div className="title">{name}</div>
                        <div className="sub">
                            <div className="size">{bytesToReadable(size)}</div>
                            {subExtra}
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="progress-group">
                        <div className="progress-bar">
                            <div className="loading" style={{ width: loadingPercent + "%" }}></div>
                            <div className="placeholder"></div>
                        </div>
                        <div className="progress-perc">{loadingPercent + "%"}</div>
                    </div>
                    {(!noAbort || !noRetry) && (
                        <div className="control">
                            {onAbort && (
                                <Button onClick={onAbort} disabled={noAbort} transparent>
                                    <AbortIcon titleAccess="Abort" />
                                </Button>
                            )}
                            {onRetry && (
                                <Button onClick={onRetry} disabled={noRetry} transparent>
                                    <RestartIcon titleAccess="Retry" style={{ width: "1.4rem" }} />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
                {isError && (
                    <span className="error-message">
                        <WarningIcon style={{ color: "red" }} /> Error: Something went wrong. Please try again later or contact support.
                    </span>
                )}
                {(!loadingPercent || loadingPercent == 100) && (
                    <Button className="prev-clear danger" onClick={onRemove} transparent>
                        <ClearIcon />
                    </Button>
                )}
            </div>
        </div>
    );
};

const PreviewFile = ({ id, file, state, percent, abort, remove, item }) => {
    const size = file.size;
    const name = file.name;
    const type = file.type;
    const isError = item.state === FILE_STATES.ERROR;

    const renderSwitchFileIcon = () => {
        const excel = Object.values(FILE_MIME_TYPES.EXCEL);
        const pdf = [FILE_MIME_TYPES.PDF];
        const image = Object.values(FILE_MIME_TYPES.IMAGE);

        if (excel.includes(type)) {
            return <img style={{ width: "90%" }} src="/images/excel.png" alt="" />;
        } else if (pdf.includes(type)) {
            return <img style={{ width: "90%" }} src="/images/pdf.png" alt="" />;
        } else if (image.includes(type)) {
            return <img style={{ width: "90%" }} src="/images/image.png" alt="" />;
        }
    };

    // const onAbort = abort && useCallback(() => abort(id), [abort, id]);
    // const onRetry = retry && useCallback(() => retry(id), [retry, id]);

    const onRemove = useCallback(() => {
        if (state === FILE_STATES.UPLOADING) abort(id);
        typeof remove === "function" && remove();
    }, [abort, id]);

    const prevProps = {
        name,
        size,
        icon: renderSwitchFileIcon(),
        loadingPercent: percent,
        // onAbort,
        // onRetry,
        noAbort: state !== FILE_STATES.UPLOADING,
        noRetry: !isItemError(state),
        onRemove,
        isError
    };

    return <PreviewItem {...prevProps} />;
};

const PreviewFolder = ({ batchId, files, filesCompleted, percent, state, abort, remove }) => {
    const findOneRelativePath = files.find((f) => !!f.file.webkitRelativePath || !!f.file.hdcFullPath);
    const totalSize = files.reduce((prev, curr) => prev + curr.file.size, 0);
    const name = findOneRelativePath
        ? (findOneRelativePath.file.webkitRelativePath && findOneRelativePath.file.webkitRelativePath.split("/").filter(Boolean).shift()) ||
          (findOneRelativePath.file.hdcFullPath && findOneRelativePath.file.hdcFullPath.split("/").filter(Boolean).shift())
        : "Folder";

    // const onAbort = abort && useCallback(() => abort(batchId), [abort, batchId]);
    // const onRetry = retry && useCallback(() => retry(batchId), [retry, batchId]);

    const onRemove = useCallback(() => {
        if (isBatchItemProgress(state)) abort(batchId);
        typeof remove === "function" && remove();
    }, [abort, batchId]);

    const prevProps = {
        name,
        size: totalSize,
        icon: <FolderIcon />,
        loadingPercent: percent,
        // onAbort,
        // onRetry,
        noAbort: !isBatchItemProgress(state),
        noRetry: !isItemError(state),
        onRemove,
        subExtra: (
            <div>
                {filesCompleted}/{files.length} Files
            </div>
        )
    };

    return <PreviewItem {...prevProps} />;
};

const FileUpload = forwardRef(function FileUpload(
    { instance, uploader, batch, files, isFolder, children, error, onDropError, accepts, style, onRemove },
    ref
) {
    const inputRef = useRef(null);

    const filePreview = batch && !isFolder;
    const folderPreview = batch && isFolder;

    const handleChange = (files) => {
        // if is folder is false and files is empty that means someone attempted to upload a folder to a file upload which is not allowed.
        if (!files.length && !isFolder) return;
        uploader.addFiles(files);
    };

    const handleRemove = (item) => {
        if (!isFolder) {
            const clonebatch = cloneDeep(batch);
            clonebatch.items = clonebatch.items.filter((f) => f.file.id !== item.file.id);
            if (!clonebatch.items.length) {
                inputRef && inputRef.current && (inputRef.current.value = "");
            }
            uploader.setBatch(clonebatch.items.length ? clonebatch : null);
        } else {
            uploader.setBatch(null);
            inputRef && inputRef.current && (inputRef.current.value = "");
        }
        typeof onRemove === "function" && onRemove();
    };

    // if there is an error reset file
    if (error) {
        inputRef && inputRef.current && (inputRef.current.value = "");
    }

    return (
        <div className="tk-file-upload" style={style || {}}>
            <DropZone ref={inputRef} onChange={handleChange} isFolder={isFolder} accepts={accepts} error={!!error} onError={onDropError} />
            {children}
            {filePreview && (
                <div className="tk-file-upload__preview-list">
                    {files.map((item) => (
                        <PreviewFile
                            key={item.id}
                            id={item.id}
                            file={item.file}
                            percent={item.completed}
                            state={item.state}
                            abort={instance?.abort}
                            item={item}
                            remove={() => handleRemove(item)}
                        />
                    ))}
                </div>
            )}
            {folderPreview && (
                <div className="tk-file-upload__preview-list">
                    <PreviewFolder
                        batchId={batch.id}
                        files={batch.items}
                        filesCompleted={uploader.FilesCompleted}
                        percent={uploader.Loading.percent}
                        state={uploader.Loading.state}
                        abort={instance?.abortBatch}
                        remove={handleRemove}
                    />
                </div>
            )}
            <button ref={ref} onClick={() => batch && batch.items.length && uploader.processUploads()} style={{ display: "none" }}></button>
        </div>
    );
});

PreviewItem.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    size: PropTypes.number,
    loadingPercent: PropTypes.number,
    onAbort: PropTypes.func,
    onRetry: PropTypes.func,
    onRemove: PropTypes.func,
    noAbort: PropTypes.bool,
    noRetry: PropTypes.bool,
    subExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    isError: PropTypes.bool
};

PreviewFile.propTypes = {
    id: PropTypes.string,
    file: PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string
    }),
    state: PropTypes.string,
    percent: PropTypes.number,
    abort: PropTypes.func,
    remove: PropTypes.func,
    item: PropTypes.object
};

PreviewFolder.propTypes = {
    batchId: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.object),
    filesCompleted: PropTypes.number,
    percent: PropTypes.number,
    state: PropTypes.string,
    abort: PropTypes.func,
    remove: PropTypes.func
};

FileUpload.propTypes = {
    instance: PropTypes.object,
    uploader: PropTypes.object,
    batch: PropTypes.object,
    files: PropTypes.array,
    isFolder: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    error: PropTypes.object,
    accepts: PropTypes.arrayOf(PropTypes.string),
    onDropError: PropTypes.func,
    style: PropTypes.object,
    onRemove: PropTypes.func
};

export default FileUpload;
