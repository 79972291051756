import { BASE_PATH_URI } from "../../../app/apiSlice";
import { EMPLOYEE_FILES } from "../../../common/utilities/const";

export const STEP = {
    STEP_1: { idx: 0, name: "Upload Excel File", required: true },
    STEP_2: { idx: 1, name: "Checking for Duplicate", required: true },
    STEP_3: { idx: 2, name: "Upload Files" },
    STEP_4: { idx: 3, name: "Show Result" }
};

export const UPLOAD_ENDPOINT = BASE_PATH_URI + "/api/company/employee/bulk-steps/upload-files";

export const FILE_SIZE_LIMIT = 3; // 3MB

export const POSSIBLE_FILES = Object.values(EMPLOYEE_FILES);

export const EXCLUDED_KEY_FROM_POSSIBLE_FOLDER_NAME_VALUES = ["rowNum", "email_address", "mobile_number", "first_name", "last_name"];

export const ERROR_CODE = {
    INVALID_CSV_FIELDS: "invalid-csv-fields",
    NOT_ALLOWED_UNIQUE_VALUES: "not-allowed-unique-values",
    INVALID_MOBILE_NUMBER: "invalid-mobile-number",
    INVALID_EMAIL_ADDRESS: "invalid-email-address",
    INVALID_FILE_ID_LENGTH: "invalid-file-id-length",
    INVALID_DATE: "invalid-date",
    INVALID_ENUM: "invalid-enum",
    MISSING_REQUIRED_FIELDS: "missing-required-fields",
    INVALID_CURRENCY_VALUE: "invalid-currency-value",
    DUPLICATE_UNIQUE_VALUES_ACROSS_DB: "duplicate-unique-values-across-db"
};

export const EMPLOYEE_ENTIRE_DB_UNIQUE = ["mobile_number", "residenceID", "visaID", "passportID", "email_address"];

export const EMPLOYEE_COMPANY_SPECIFIC_DB_UNIQUE = [["first_name", "last_name"], "labor_card_number", "generatedID"];

export const EMPLOYEE_DB_UNIQUES = EMPLOYEE_COMPANY_SPECIFIC_DB_UNIQUE.concat(EMPLOYEE_ENTIRE_DB_UNIQUE).flat();

export const UNIQUE_VALUES_USED_FOR_UPLOADING_FILES = ["companyID", "residenceID", "passportID", "visaID", "labor_card_number"];

export const SUPPORTED_DOCUMENT_FILE_NAME_TO_UPLOAD = Object.values(EMPLOYEE_FILES).map((file) => file.key);
