import React, { useState } from "react";
import PropTypes from "prop-types";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../../common/components/extra/Button";
import UpdateFilesModal from "./UpdateFilesModal";
import MyTooltip from "../../../common/components/extra/Tooltip";

const LINK_STYLE = {
    textDecoration: "underline",
    fontStyle: "italic",
    color: "red",
    cursor: "pointer"
};

function UpdateFilesButton({
    onClick,
    noInternalModal,
    forceInline,
    id,
    transparentOverlay,
    hasBack,
    isIcon,
    style = {},
    disabled,
    withLabel,
    useEditIcon
}) {
    const [isOpen, setOpen] = useState(false);

    const handleOnClick = () => {
        setOpen(true);
        onClick?.();
    };

    const label = "Update Files";

    const renderButton = forceInline ? (
        <span style={{ ...LINK_STYLE, style }} className="semi-bold" onClick={handleOnClick}>
            Click to {label}
        </span>
    ) : (
        <Button
            options={{ style: { minWidth: "max-content", ...style } }}
            onClick={() => !disabled && handleOnClick()}
            className="danger"
            afterExtra={<AttachFileIcon style={{ width: "1.1rem", pointerEvents: "none" }} />}
            disabled={disabled}
            small
            mini
        >
            <span style={{ whiteSpace: "nowrap" }}>{label}</span>
        </Button>
    );

    if (isIcon) {
        return (
            <MyTooltip
                className={disabled ? "disabled" : "pointer hover-svg"}
                message={withLabel ? "" : "Update Files"}
                onClick={() => !disabled && handleOnClick()}
                style={{ ...style, display: "flex", gap: ".5rem", alignItems: "center" }}
            >
                {useEditIcon ? (
                    <EditIcon className="fade" style={{ width: "1.3rem", pointerEvents: "none" }} />
                ) : (
                    <AttachFileIcon style={{ width: "1.3rem", pointerEvents: "none" }} />
                )}
                {(withLabel && "Update Files") || ""}
            </MyTooltip>
        );
    }
    return (
        <>
            <div className="small-font flex gap-05 wrap">{renderButton}</div>
            {isOpen && !noInternalModal && (
                <UpdateFilesModal
                    open={isOpen}
                    onClose={() => setOpen(false)}
                    onFinish={() => setOpen(false)}
                    id={id}
                    transparentOverlay={transparentOverlay}
                    onBack={hasBack && (() => setOpen(false))}
                />
            )}
        </>
    );
}

export default UpdateFilesButton;

UpdateFilesButton.propTypes = {
    id: PropTypes.number,
    transparentOverlay: PropTypes.bool,
    isIcon: PropTypes.bool,
    hasBack: PropTypes.bool,
    onClick: PropTypes.func,
    noInternalModal: PropTypes.bool,
    forceInline: PropTypes.bool,
    disabled: PropTypes.bool,
    withLabel: PropTypes.bool,
    style: PropTypes.object,
    useEditIcon: PropTypes.bool
};
